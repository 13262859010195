
<div class="container-fluid sidra-main-footer-one d-flex align-items-center py-4">
    <div class="container-lg">
        <div class="col-12 row mx-0 ">
            <div class="col-lg-4 col-md-3  col-12 d-flex justify-content-md-start justify-content-center align-items-center">
                <img src="../../../assets/icons/Sidra Logo white-16 (1).svg" alt="" class="logo cw">
            </div>
            <div class="px-0 col-lg-4 col-md-6 col-12 d-flex justify-content-center align-items-center mt-md-0 mt-4">
                <span class="fz-12 cw">@2023 www.sidrabazar.com All Right Reserved</span>
            </div>
            <div class=" col-lg-4 col-md-3  col-12 d-flex justify-content-md-end justify-content-center align-items-center mt-md-0 mt-4">
                <div class="d-flex flex-row">
                    <button class="rounded-btn" (click)="linkClick('facebook')">
                        <i style="color: white;" class="bi bi-facebook"></i>
                    </button>
                    <button class="rounded-btn ms-2" (click)="linkClick('twitter')">
                        <i style="color: white;" class="bi bi-twitter"></i>
                    </button>
                    <button class="rounded-btn ms-2"(click)="linkClick('whatsapp')">
                        <i style="color: white;" class="bi bi-whatsapp"></i>                    
                    </button>
                    <button class="rounded-btn ms-2"  (click)="linkClick('insta')">
                        <i style="color: white;" class="bi bi-instagram"></i>
                    </button>
                </div>            
            </div>
        </div>
    </div>
</div>