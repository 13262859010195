import { Directive, ElementRef, Renderer2 } from '@angular/core';

@Directive({
  selector: 'input[preventAutofill]'
})
export class PreventAutofillDirective {

  constructor(private el: ElementRef, private renderer: Renderer2) { }

  ngOnInit() {
    this.renderer.setAttribute(this.el.nativeElement, 'autocomplete', 'off');
  }

}
