<app-sidraheader *ngIf="mainheader==true" style="position: fixed;width: 100%;top: 0;left: 0;z-index: 999;"></app-sidraheader>
<app-menu-header></app-menu-header>

<div *ngIf="isLoading" class="container-fluid  loading-overlay d-flex justify-content-center align-items-center"
    style="height:100%">
    <img src="../../../assets/images/New-anime2.gif" alt="" width="80">
</div>

<div class="container-fluid px-0">

    <!-- section-1 banner  -->
    <div class="section-1">
        <div>
            <img src="../../../assets/images/Rectangle 21794 (1).png" alt="">
        </div>
        <div class="logo_div py-sm-5 py-0 pb-3">
            <div class="sellerlogo d-flex justify-content-center">
                <img class="p-2" src={{fullstoredetails?.store_logo}} alt="">

            </div>
            <div class="d-flex flex-column justify-content-center mt-2">
                <div class="d-flex flex-column" style="text-align: center;">
                    <span class="fw-bold fz-20">{{fullstoredetails?.company_display_name}}</span>
                    <span class="fz-12 fw-500 mt-1" style="color: #6d6d6d;">Hypermarket</span>
                </div>
                <div class="icons d-flex justify-content-center align-items-center cursor mt-2">
                    <button class="Store_share px-3 py-2" type="button">Share this Store</button>
                    <div uk-dropdown="mode: click" class="ukdrop" style="left:60%">
                        <div class="d-flex flex-column">
                            <span class="media mb-3"><i class="bi bi-envelope me-2 fz-16"
                                    style="color: #717478;"></i>E-mail</span>
                            <span class="media mb-3"><i style="color: skyblue;"
                                    class="bi bi-facebook me-2 fz-16"></i>Facebook</span>
                            <span class="media mb-3"><i class="bi bi-instagram me-2 fz-16 insta"
                                    style="color: #717478;"></i>Instagram</span>
                            <span class="media mb-3"><i style="color: skyblue;"
                                    class="bi bi-twitter me-2 fz-16"></i>Twitter</span>
                            <span class="media mb-3"><i style="color: red;"
                                    class="bi bi-pinterest me-2 fz-16"></i>Pintrest</span>
                            <span class="media"><i style="color: lightgreen;"
                                    class="bi bi-whatsapp me-2 fz-16"></i>Whatsapp</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- section-1 banner  -->

    <!-- section-2  -->
    <div class="col-12 row mx-0 px-0 py-0 d-flex justify-content-center mb-1">
        <div class="col-12 d-flex justify-content-center align-items-center pt-3" style="background-color: white;">
            <span class="fz-13 fw-bold py-2 cursor linecolor" (click)="product()"
                [class.linecolorSelected]="product_view==true">
                Our Products
            </span>
            <span class="fz-13 fw-bold py-2 ms-3 cursor linecolor" (click)="profile()"
                [class.linecolorSelected]="profile_view==true">
                Profile Details
            </span>
        </div>
    </div>

    <!-- product  -->

    <div class="col-12 row mx-0 px-sm-3 px-0 py-4 pb-5" style="background-color: white;" *ngIf="product_view==true">
        <div class="col-12 d-flex justify-content-between align-items-center">
            <div class="d-flex flex-row">
                <span class="fw-500" style="color: #717478;">Our Product</span>
                <span class="ms-4 fw-500">Total {{datacount?.count}} Product</span>
            </div>
            <div class="d-flex justify-content-endflex-row form-control w-50">
                <input type="text" value="" [(ngModel)]="searchData" style="border: none; outline: none; "
                    class=" fz-13 text-truncate w-100" placeholder="search ..." (keydown.enter)="searchResults()" (input)="searchResults()">
                <button class="search-button "><i class="bi bi-search fw-bold" (keydown.enter)="searchResults()"
                        (click)="searchResults()"></i></button>
            </div>
        </div>

        <div class="col-12 row bgw  mx-0 pt-4 pb-3 mt-2 px-2 pb-5">
            <div class="d-flex flex-wrap px-sm-2 px-0" *ngIf="fulldata?.length!=0">
                <div class="px-0 mt-3 col-xl-2 col-lg-3 col-sm-4 col-6" *ngFor="let product of fulldata">
                    <app-product-card-store [products]="product">
                    </app-product-card-store>

                </div>
            </div>
            <div  class="d-flex justify-content-center align-items-center px-sm-2 px-0" *ngIf="loding==true">
                <div class="px-0 mt-3 d-flex justify-content-center align-items-center col-xl-2 col-lg-3 col-sm-4 col-6">
                    <img src="../../../assets/images/New-anime2.gif" alt="" width="80">
                </div>
            </div>
            <div class="d-flex justify-content-center align-items-center px-sm-2 px-0" *ngIf="fulldata?.length==0">
                <div class="px-0 mt-3 d-flex justify-content-center align-items-center col-xl-2 col-lg-3 col-sm-4 col-6">

                    <span>No Data</span>
                </div>
            </div>
        </div>
    </div>

    <!-- profile  -->

    <div class="col-12 row mx-0 pb-5 d-flex justify-content-center" style="background-color: white;"
        *ngIf="profile_view==true">

        <div class="col-md-6 col-sm-7 col-11 my-5 p-4" style="border: 1px solid #e6e6e6;border-radius: 5px;">
            <div class="d-flex flex-column">
                <span class="fw-bold">About Company</span>
                <span class="mt-2">{{fullstoredetails?.description}}</span>
            </div>
            <div class="d-flex flex-column mt-3">
                <span class="fw-bold">TRN No</span>
                <span class="mt-2">{{fullstoredetails?.company_tax_numer}}</span>
            </div>
            <div class="d-flex flex-column mt-3">
                <span class="fw-bold">Contact Info</span>
                <span class="mt-2">Phone : <i>{{fullstoredetails?.address?.contact?.primary}}</i></span>
                <span>Email : <i>{{fullstoredetails?.address?.email}}</i></span>
            </div>
        </div>



    </div>
</div>

<app-footer></app-footer>