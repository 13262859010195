

<div class="sidraCard card d-flex flex-column  px-lg-3 px-2 pt-2 pb-3 me-1" style="border-radius: 0px;height: 100%;">
  <div class="d-flex justify-content-end">
     <div class="uk-inline">
        <div class="icons wishlist  p-0 d-flex justify-content-center align-items-center cursor uk-button uk-button-default" (click)="wishListdropDown(products)">
            <i [ngClass]="is_wishlisted==true?'wish_after':' wish_befor'" class="bi bi-suit-heart-fill"></i>
        </div> 
        <div class="p-0" *ngIf="wishListActived==true" style="border-radius: 5px;" uk-dropdown="mode: click">
            <div class="d-flex flex-row py-3 px-3 align-items-center border-bottom cursor groupListOver"  *ngFor="let j of groupListArray;let i=index" (click)="movetoAnother(products,j.id,i)">
                <input class="form-check-input" type="radio" [checked]="is_wish_active==i">
                <span class="fz-13 ms-2">{{j.group_name}} </span>   
            </div>
        </div> 
    </div>
  </div>
  <div class="d-flex justify-content-center align-items-center card-img-top mb-2" (click)="navigateProductPage(products.variant_id)">
      <img [src]="products.image1||products.image" alt="">
  </div> 
  <div class="mb-3 h-30">
     <span class="c-black fz-13 fw-500 mt-3" id="product-name"> 
         {{products.name | titlecase}} 
     </span> 
     <span class="d-flex flex-row mt-2 fz-15 c-black">
         <i class="fz-13 fw-500">AED</i> <i class="c-black fw-600 fz-14 ms-1"> {{products.selling_price}}</i>
     </span> 
  </div> 
  <div class="mt-md-0 pt-2" *ngIf="products?.sales_uom_name">
     <span class="fz-12">Unit :</span>
     <span class="fz-12 ms-1" style="color: #717478;">{{products.sales_uom_name |titlecase}}</span>
  </div>
  <div class="mt-2" *ngIf="products?.is_stock==true"> 
     <button *ngIf="is_addtocart==true&&inCartIsActive==false&&withAndWithoutLoginBtn==true" class="add-to-cart-btn fz-13 fw-400 px-3 py-2" (click)="addToCart(products)">Add to Cart <span *ngIf="dot==true" class="loading-dots"></span></button>
     <button *ngIf="is_addtocart==true&&inCartIsActive==false&&withAndWithoutLoginBtn==false" class="add-to-cart-btn fz-13 fw-400 px-3 py-2" (click)="addToCartLocal(products)">Add to Cart <span *ngIf="dot==true" class="loading-dots"></span></button>
     <button *ngIf="is_addtocart==false&&inCartIsActive==true" class="add-to-cart-btn fz-13 fw-400 px-3 py-2" [routerLink]="['/cart']">In Cart</button>
  </div>
</div>

