<div class="card p-3 border-0" >
    <h6 class="fw-bold min-fit-width col-12 mb-2 fz-15 text-capitalize text-truncate-1" >Deal of this week</h6>
    <div class="d-flex flex-wrap row gx-2" dir="rtl">
        <div class="col-6 px-1">
            <div class="deals p-3 d-flex justify-content-center align-items-center">
                <img src="../../../assets/assets/moto-g-pure-pdp-kv-render-deep-indigo-1-.svg" alt="" class="">
            </div>
        </div>
        <div class="col-6 px-1">
            <div class="deals p-3 d-flex justify-content-center align-items-center">
                <img src="../../../assets/assets/moto-g-pure-pdp-kv-render-deep-indigo-1-.svg" alt="" class="">
            </div>
        </div>
        <div class="col-6 px-1 mt-2">
            <div class="deals p-3 d-flex justify-content-center align-items-center">
                <img src="../../../assets/assets/moto-g-pure-pdp-kv-render-deep-indigo-1-.svg" alt="" class="">
            </div>
        </div>
        <div class="col-6 px-1 mt-2">
            <div class="deals p-3 d-flex justify-content-center align-items-center">
                <img src="../../../assets/assets/moto-g-pure-pdp-kv-render-deep-indigo-1-.svg" alt="" class="">
            </div>
        </div>
    </div>
</div>