<div class="container-fluid p-0">
    <div class="personalised-store">
        <img class="personalised-store__img" [src]="storeBG" alt="">
        <div class="personalised-store__slider">
            <h4 class="fw-bold text-white min-fit-width col-12 mb-4 ms-2 ms-md-0 ps-md-4 ps-3 fz-22">
                {{storeTitle}}
            </h4>
            <div class="col-12 multi-slider">
                <div class="uk-position-relative uk-visible-toggle uk-dark px-10px" tabindex="-1" uk-slider>
                    <ul class="uk-slider-items uk-child-width-1-3 uk-child-width-1-4@s uk-child-width-1-6@m">
                        <li class="pb-3 border-0 d-flex flex-column" *ngFor="let product of personalStore; let i = index">
                            <div class="product-card_2 card p-3 w-90 mx-auto position-relative">
                                <img class="mx-auto pb-2" [src]="product.itemSrc" [alt]="product.name">
                                <span class="fz-13">{{product.name}}</span>
                            </div>
                        </li>
                    </ul>
                    <a class="uk-position-center-left uk-position-small uk-hidden-hover" href="#" uk-slidenav-previous uk-slider-item="previous"></a>
                    <a class="uk-position-center-right uk-position-small uk-hidden-hover" href="#" uk-slidenav-next uk-slider-item="next"></a>
                </div>
            </div>
        </div>
    </div>
</div>