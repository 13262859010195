import { MessengerService } from './../../services/messenger.service';
import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { OrderService } from 'src/app/services/order.service';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { CartService } from 'src/app/services/cart.service';
import { LocationStrategy } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';

let headers:any
@Component({
  selector: 'app-successorder',
  templateUrl: './successorder.component.html',
  styleUrls: ['./successorder.component.scss']
})
export class SuccessorderComponent implements OnInit {
  order:any
  weburl = environment.footer_credit_link
  webdomain = environment.footer_credit_text
  statusMessage:any
  designChage=false
  designChageOne=false
  productDetails:any
  orderDetail:any
  orderDetail1:any
  paymentStatus:any="success"
  stepper:any
  api_user=environment.api_user
  userDetails:any
  constructor(private msg:MessengerService,
              private orderService:OrderService,
              private route:ActivatedRoute,
              private router:Router,
              private http:HttpClient,
              private location: LocationStrategy,
              ) {
                history.pushState(null, '', window.location.href);
                this.location.onPopState(() => {
                    history.pushState(null, '', window.location.href);
                    this.stepper.previous();
                }); 
              }
  ngOnInit(): void {
    let user:any = localStorage.getItem('marketplaceUser')
    this.userDetails = JSON.parse(user) 
    let tokens=this.userDetails?.token
    headers=new HttpHeaders({
      Authorization: `token ${tokens}`
    })
    this.http.get(this.api_user+"/user-account_check-auth-token",{headers}).toPromise().then((data:any)=>{
    })
    .catch((error) => {
      localStorage.clear()
      this.router.navigate(['Unauthorized']);
    });
      let orderId:any = localStorage.getItem("afterorderProduct")
      let orderIdFind = JSON.parse(orderId)
      this.orderService.getOrderDetail(orderIdFind).subscribe((data:any)=>{
        this.orderDetail=data.data
      }) 
      this.orderService.singleDetails(orderIdFind).subscribe((data:any)=>{
        this.orderDetail1=data.data.results[0]
      }) 
      this.msg.getOrderResponse().subscribe((data:any)=>{
        this.order = data.data
      })
      let payment:any = localStorage.getItem("payment_reference")
      let payment_ref = JSON.parse(payment)
      // console.log(payment_ref,"=======");
      if(payment_ref!=null){
        let status={
          reference_id:payment_ref
        } 
        this.orderService.paymentStatus(status)
        this.msg.getPaymentStatus().subscribe((d:any)=>{
           this.paymentStatus=d 
        }) 
      } 
  } 
  routeToDetailPage(){
    let url="/sidra-profile/orders"
    this.router.navigate([url])
  } 

}
