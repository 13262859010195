import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { MessengerService } from 'src/app/services/messenger.service';

@Component({
  selector: 'app-storeweb',
  templateUrl: './storeweb.component.html',
  styleUrls: ['./storeweb.component.scss']
})
export class StorewebComponent implements OnInit {
  productRoute = { name: String };
  our_product = true
  profile_details = false
  profile_view = false
  product_view = true
  inventory_id: any
  fulldata: any
  fullstoredetails: any
  datacount: any
  isLoading = true
  linkcode: any
  searchData: any
  loding:boolean=false
  mainheader:boolean=true
  constructor(private route: ActivatedRoute,
    private auth: AuthService,
    private msg: MessengerService) { }

  ngOnInit(): void {
    this.route.params.subscribe((data: any) => {
      this.productRoute = {
        name: this.route.snapshot.params['id'],
      }
      this.inventory_id = atob(data.id)
      // console.log("inventory_id", this.inventory_id);
      this.linkcode = "https://live.sidrabazar.com/#/seller-profile?seller_mappper_id=jjk768906789sdsdsd"
      // this.linkcode="https://sidrabazar.com?referal_code="+this.userData?.username

    })
    this.msg.getheaderhide().subscribe(()=>{
      const isMobileView = window.innerWidth <= 575;
     if(isMobileView){
      this.mainheader=false
     }
      
    })
  
    this.msg.getshowheader().subscribe(()=>{
      this.mainheader=true
    })
    this.storeproduct()
    this.storedetails()
  }

  profile() {
    this.profile_view = true
    this.product_view = false
  }

  product() {
    this.profile_view = false
    this.product_view = true
  }

  storedetails() {

    this.auth.storedetails(this.inventory_id)
    this.msg.getstoredetails().subscribe((d: any) => {
      this.fullstoredetails = d.data.results[0]
      // console.log("fullstoredetails",this.fullstoredetails);
    })
  }

  storeproduct() {
    let data = {
      api_from: "web",
      inventory_id: this.inventory_id,
      customer_group_code: null
    }
    this.auth.storeproductdetails(data)
    this.msg.getstoreproduct().subscribe((data: any) => {
      this.isLoading = false
      setTimeout(() => {
        this.isLoading = false
      }, 2000);
      this.datacount = data.data
      console.log("datacount",this.datacount);
      this.fulldata = data.data.results
      // console.log("fulldata",this.fulldata);
    })

  }


  searchResults() {
    this.loding=true
    let result = {
      name: this.searchData,
      inventory_id:this.inventory_id
    }
  if(this.searchData){
    this.auth.storesearch(result)
    this.msg.getfilterstoredetails().subscribe((data:any)=>{
      this.loding=false
  this.fulldata=data.data.results
  console.log("filter",this.fulldata);
  
    })
  }else{
    this.loding=false
    this.fulldata=this.datacount.results
  }

  }

}
