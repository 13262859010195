<div class="container-fluid bgw px-sm-5 px-0"  style="position: fixed;width: 100%;top: 0; left: 0; z-index: 999;">
    <div class=" row mx-0 py-2 px-sm-0 px-2 d-flex align-items-center justify-content-between">
        <div class=" col-5 logo px-0">
            <img src="../../../assets/icons/sidra_logo.png" alt="" [routerLink]="['/']">
        </div>
        <div class="col-7 d-flex align-items-center justify-content-sm-start justify-content-end px-0">
            <span class="fw-bold head_text"> Sell on Sidrabazar</span>
        </div>
    </div>
</div>


<div class="col-12 row mx-0 mt-5" *ngIf="registerpage==true ">
    <div class="texxt py-sm-5 py-3 px-sm-5 px-2">

        <span class="fz-12 fw-300 cursor" [routerLink]="['/']"> Home</span> >
        <span class="fz-12 fw-bold"> Seller Registration</span>

    </div>
    <div class="col-xl-6 col-lg-7 col-12 d-flex justify-content-center py-sm-5 py-3 px-sm-5 px-2  mt-5">
        <ul id="progressbar-1" class="  ">
            <li [class.step0]="!firstform" [class.active]="firstform || !firstform" id="step1"
                style="display: grid; justify-content: start;"></li>
            <li [class.step0]="!secondform" [class.active]="secondform" id="step2"
                style="display: grid; justify-content: start;"></li>
            <li [class.step0]="!otpDisplay" [class.active]="otpDisplay" id="step3"
                style="display: grid; justify-content: start; width: 0%;"></li>
        </ul>
        <div class=" bgw px-sm-5 px-3 py-4 mt-5  w-100" style="height: fit-content;" *ngIf="form==true">
            <h2 class="fw-bold " *ngIf="firstform==true" style="text-align: center;font-size: 30px;">Seller Registration
            </h2>
            <span class="fw-bold fz-14 pt-3 pb-3 ms-1" *ngIf="secondform==true" (click)="Back()"><span class="fw-bold"
                    uk-icon="arrow-left"></span>Back</span>

            <div>

                <div *ngIf="firstform==true">

                    <div class="form-group ">
                        <input type="text" class="form-control" placeholder="Business Name" id="BusinessName"
                            name="BusinessName" [(ngModel)]="BusinessName" required>
                    </div>

                    <div class="form-group mt-3" *ngIf="modal==true" uk-toggle="target:#location_address">
                        <span class="form-control fz-13 cursor"
                            style="text-align: center; color: #FF9900; border: 1px solid #FF9900;"><i
                                class="bi bi-geo-alt me-2"></i>Locate your address</span>
                    </div>

                    <div class="form-group mt-3" *ngIf="address==true">
                        <div class="form-control d-flex align-item-center justify-content-between">
                            <span class=" fz-12 fw-bold ">{{street}},{{district}},{{state}},{{country}}</span>
                            <span class="fz-12 cursor" style="color: #FF9900;"
                                uk-toggle="target:#location_address">change</span>
                        </div>
                    </div>

                    <div class="form-group mt-3">
                        <input type="text" class="form-control" placeholder="Business Email" id="BusinessEmail"
                            name="BusinessEmail" [(ngModel)]="BusinessEmail"(ngModelChange)="validateEmail()">
                        <!-- <div *ngIf="firstform && !BusinessEmail" class="text-danger" >Business Email is required.</div> -->
                        <div *ngIf="invalidEmail" class="text-danger fz-11">Invalid Email Format(example@gmail.com).</div>
                    </div>

                    <div class="form-control d-flex flex-row mt-3 p-0">
                        <select class="form-select w-25"
                            style="border-radius: 0px; outline: none; box-shadow: none;border:none"
                            [(ngModel)]="country_code" (change)="Country">
                            <option *ngFor="let con of contry_code_list" [value]="con.code">{{con.code}}</option>
                        </select>
                        <input type="text" class="form-control" style="font-size: 12px;border:none;"
                            placeholder="Business Phone No" id="BusinessContactNo" name="BusinessContactNo"
                            [(ngModel)]="BusinessContactNo" (ngModelChange)="validatePhoneNumber()">
                    </div>
                    <div *ngIf="invalidPhoneNumber" class="text-danger fz-11 mb-3">Invalid Phone Number Format.</div>

                
                    <div class="form-group mt-3">
                        <input type="text" class="form-control" placeholder="TRN No" id="TRN" name="TRN"
                            [(ngModel)]="TRN">
                    </div>

                    <div class="form-group mt-3">
                        <input type="text" class="form-control" placeholder="Contact Person Name" id="PersonName"
                            name="PersonName" [(ngModel)]="PersonName">
                    </div>

                    <div class="form-group mt-3">
                        <div class="input-group">
                            <input type="{{ showPassword ? 'text' : 'password' }}" class="form-control"
                                style="border-right: none;" placeholder="Password" id="Password" name="Password"
                                [(ngModel)]="Password">
                            <div class="input-group-append">
                                <button class="form-control" style="border-left:none ;"
                                    (click)="showPassword = !showPassword">
                                    <!-- <span class="fa" [ngClass]="showPassword ? 'bi-eye-slash' : 'bi-eye'"></span> -->
                                    <i class="bi " [ngClass]="showPassword ? 'bi-eye' : 'bi-eye-slash'"></i>
                                </button>
                            </div>
                        </div>
                    </div>

                    <div class="col-12 row mx-0 d-flex align-items-center mb-5">
                        <div class="form-check d-flex align-items-center mb-0">
                            <input type="checkbox" class="form-check-input" id="isChecked" (click)="termsAnduse($event)"
                                [(ngModel)]="isChecked">
                            <label class="form-check-label fz-12 fw-500 ms-2 mt-1 cursor" for="exampleCheck1">I accept
                                all
                                <i class="c-FF9900 mx-1 fz-12 fw-500" (click)="privacy('Terms Of Use')"
                                    routerLinkActive="router-link-active">
                                    Terms Of Use
                                </i>
                                and
                                <i class="c-FF9900 mx-1 fz-12 fw-500" (click)="privacy('Privacy Policy')">
                                    Privacy Policy
                                </i>
                            </label>
                            <!-- [routerLink]="['/genaral-policy']" -->
                            <!-- [routerLink]="['/genaral-policy']" -->
                        </div>
                    </div>

                    <div class="form-group mb-3 d-flex justify-content-center">
                        <button type="button" class="submitbtn w-100 py-2"
                            [disabled]="BusinessName=='' || BusinessEmail=='' || invalidEmail==true || BusinessContactNo=='' || invalidPhoneNumber==true|| TRN=='' || PersonName==''  || Password.length < 8 || isChecked== false  || address==false"
                            (click)="first()">Continue</button>
                    </div>
                </div>

                <div class="pt-3" *ngIf="secondform==true">
                    <div class="">
                        <div class="form-control px-5 py-5 position-relative mb-3"
                            style="background-color: #F5F3F3; border: 2px dashed #D3D3D3;">
                            <input type="file" name="resume" id="resumeInput" class="form-control"
                                placeholder="updated Resume" (change)="onFileChangeTrade($event)" [class.hide]="Trade" #fileInput>
                            <span *ngIf="Trade" class="custom-span d-flex justify-content-center">{{Trade}}</span>
                            <span *ngIf="Trade" class="custom-change d-flex justify-content-center cursor" (click)="triggerFileInputClick(fileInput)" style="color: #FF9900; text-align: end;">Change</span>
                            <div class="label">
                                <label for="resumeInput" class="file-input-label " [class.hide]="Trade"><img
                                        class="me-1" src="../../../assets/images/add_circle.svg" alt="">Upload Trade
                                    license</label>
                            </div>
                        </div>

                        <div class="form-control px-5 py-5 position-relative mb-3 "
                            style="background-color: #F5F3F3; border: 2px dashed #D3D3D3;">
                            <input type="file" name="resume" id="resumeInput" class="form-control "
                                placeholder="updated Resume" (change)="onFileChangeTax($event)" [class.hide]="Tax" #FileInput>
                            <span *ngIf="Tax" class="custom-span d-flex justify-content-center">{{Tax}}</span>
                            <span *ngIf="Tax" class="custom-change d-flex justify-content-center cursor" (click)="triggerFileInputClick(FileInput)" style="color: #FF9900; text-align: end;">Change</span>
                            <label for="resumeInput" class="file-input-label" [class.hide]="Tax"><img class="me-1"
                                    src="../../../assets/images/add_circle.svg" alt="">Upload Tax</label>
                        </div>



                    </div>
                    <span>Note</span>
                    <ul class="mt-2">
                        <li>Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam,
                            nisi ut alUt enimad.</li>
                        <li>Minima veniam, quis nostrum exercitationem ullam corporis .</li>
                        <li>Suscipit laboriosam, nisi ut alUt enim ad minima veniam, quis Nostrum</li>
                        <li>Nostrum exercitationem ullam corporis suscipit Laboriosam,</li>
                        <li>nisi ut alUt enim ad minima veniam, quis nostrum.</li>
                    </ul>

                    <div class="d-flex justify-content-center">
                        <button type="submit" (click)="submitForm()" class="submitbtn w-100 py-2"
                            [disabled]="Tax==null || Trade==null">Register as a Seller</button>
                    </div>

                </div>

            </div>


        </div>

        <div class=" bgw px-5 py-4 mt-5  w-100" style="height: fit-content;" *ngIf="otpDisplay==true">
            <div class="form-group">
                <span class="fw-bold d-flex justify-content-center mb-3 " style="font-size: 20px;">Confirm OTP
                </span>
                <!-- <ng-otp-input class="px-0" header="GeeksforGeeks" (onInputChange)="onOtpChange($event)" [config]="{length:5}"></ng-otp-input> -->
                <input type="text" class="form-control mb-3" placeholder="Enter OTP" id="otp" name="otp"
                    [(ngModel)]="addressotp">
                <button type="button" class="submitbtn w-100 py-2" [disabled]="addressotp.length < 5"
                    (click)=" OtpVarification()">send</button>
            </div>
            <div class="col-12 px-1 mt-2 d-flex justify-content-center">
                <i class="fz-13 fw-500 c-FF9900 mt-2 cursor" *ngIf="subscribeTimer!=0">{{subscribeTimer}} sec</i>
                <i class="fz-13 fw-500 c-FF9900 mt-2 cursor" *ngIf="subscribeTimer==0" (click)="reSendOtp()">Resend
                    Otp</i>
            </div>
        </div>

    </div>
    <div class="col-xl-6 col-lg-5 col-12 d-flex justify-content-center" style="background-color: #FFF3E7;">
        <img class="p-5" src="../../../assets/images/Group 30612.svg" alt="">
    </div>
</div>

<div class="col-12 row mx-0 py-4"  *ngIf="successpage==true ">
    <div class="col-md-6 col-12 d-none d-md-flex align-items-center">
        <img class="p-5" src="../../../assets/images/successpage_mobileimg.svg" alt="">
    </div>
    <div class="col-md-6 col-12  d-flex justify-content-md-start justify-content-center align-items-center py-sm-5 py-3 px-sm-5 px-2">
        <div>
            <div class="successbox_1 d-flex flex-column py-sm-5 py-4 px-sm-5 px-3" style="background-color:#14A422 ;">
                <span class="text_1 d-flex align-item-center"><img class="me-1"
                        src="../../../assets/images/check_circle.svg" width="25px" alt=""> Done!</span>
                <span class="text_2">Seller Registered Successfully</span>
                <img class="successbox_1img me-1" src="../../../assets/images/check_circle.svg" width="60px" alt="">
            </div>
            <div class="successbox_2 py-sm-5 py-4 px-sm-5 px-3">
                <div class="successbox_2 d-flex flex-column ">
                    <span class="text_1">Download our app</span>
                    <span class="text_2">Seamlessly connect with a marketplace of opportunities at your
                        fingertips.</span>
                </div>
                <div class="mt-5 d-flex justify-content-start">

                    <a class="Download me-4" href="">
                        <img class="" src="../../../assets/images/playstore.svg" alt="" width="180">
                    </a>
                    <a class="Download " href="">
                        <img src="../../../assets/images/applestore.svg" alt="" width="180">
                    </a>
                </div>
                <span class="fz-10"># Click and download</span>
            </div>
        </div>

    </div>
    <div class="col-12  d-flex d-md-none align-items-center">
        <img class="p-5" src="../../../assets/images/successpage_mobileimg.svg" alt="">
    </div>
</div>



<!-- modal  -->

<div id="location_address" class="uk-flex-top" uk-modal>
    <div class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical py-2 px-0">

        <button class="uk-modal-close-default d-none d-sm-block" type="button" uk-close></button>


        <div class="col-12 row mx-0">

            <div class="search-box position-relative " style="height: 42px;">
                <div class="overflow-hidden position-relative h-100">
                    <input class="form-control bg-light fz-13 text-truncate w-100" type="text"
                        ngx-google-places-autocomplete (onAddressChange)="handleAddressChange($event)"
                        style="border-radius: 5px !important; border: 1.3px solid #F4F3F1 !important; height: 42px !important;"
                        placeholder="Search places..">
                    <button class="pointer" (click)="handleAddressChange($event)"><i class="bi bi-search"></i></button>
                </div>
            </div>

            <div class="col-12 row mx-0 px-0">
                <google-map height="300px" width="100%" [center]="center" [options]="options" [latitude]="lat"
                    [longitude]="lng" [scrollwheel]="true" (mapClick)="onMapClick($event)" [zoom]="zoom">
                    <map-marker *ngFor="let marker of markers" [position]="marker.position" [options]="marker.options">
                    </map-marker>
                    <label class="current-location-btn" (click)="getCurrentLocation()">
                        <i class="bi bi-geo-alt me-2"></i>Locate Me</label>
                </google-map>
                <span class="fz-12 fw-bold mt-2"
                    style="text-align: end;">{{street}},{{district}},{{state}},{{country}}</span>
            </div>

            <div class="col-12 row mx-0 pt-4">
                <div class="d-flex flex-row d-flex justify-content-center px-0">
                    <button class="py-2 px-3 colseMap_btn fz-14 fw-bold" (click)="closemap()">
                        Close Map
                    </button>
                    <button class="confirm_btn py-2 px-3 ms-2 fz-14 fw-bold" (click)="confirmLocations()">
                        Confirm Address
                    </button>
                </div>
            </div>
        </div>


    </div>
</div>
<app-footer3></app-footer3>