<div class="container-fluid" style="background-color: white;">
    <div class="col-12 row mx-0 pb-2 pt-3">
        <span class="fz-12" style="color: rgb(190, 188, 188);">
            Home > Fresh Food > Samsung 6.5 kg Fully-Automated washi ...
        </span>
    </div>
    <div class="col-12 row mx-0">
        <div class="col-8 py-2">
            <div class="d-flex flex-row review_pro p-3">
                <div class="d-flex align-items-center justify-content-center px-3">
                    <img src="../../../assets/assets/moto-g-pure-pdp-kv-render-deep-indigo-1-.svg" alt="" width="50">
                </div>
                <div class="d-flex flex-column ms-2">
                    <span class="fz-13 fw-bold">
                       Samsung 
                    </span>
                    <span class="fz-14 fw-400 mt-1">
                       Musafir Pure Loium Nahjtu Sodium Natural WAE 1BVDD098Loium Nahjtu Sodium Natural WAESodium Natural WAE 1BVDD098Loium Nahjtu 
                    </span>
                </div>
            </div>
            <div class="mt-4">
                <div class="">
                    <span class="fz-14 fw-bold">
                        Rating & Reviews 
                    </span>
                    <span class="fz-12 fw-bold c-main ms-2">
                        How i review a product ?
                    </span>
                </div>
                <div class="rating_box mt-2">
                    <div class="border-bottom">
                        <div class="d-flex flex-row p-3">
                            <div class="w-50 d-flex flex-column align-items-center ">
                                <img src="../../../assets/assets/Ellipse 181.svg" alt="" width="40">
                                <span class="fz-13 fw-bold mt-2">Manju Lakshmi EB</span>
                            </div>
                            <div class="d-flex flex-column ms-2">
                                <span>
                                    <img src="../../../assets/assets/rating.svg" alt="">
                                    <i class="fz-13 fw-bold ms-2">
                                        (4.5)
                                    </i> 
                                </span>
                                <span class="fz-13 fw-bold mt-3 mb-1">
                                    Lorem ipsum dolar sit amet
                                </span>
                                <p class="fz-13" style="line-height: 1.5rem;">
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. 
                                    Mauris enim pellentesque pulvinar et, feugiat. 
                                    Libero ligula leo ultrices fames nunc egestas. 
                                    Mi arcu at commodo volutpat. Tortor sagittis, nulla nisi, mattis dictumst. 
                                    Leo, enim enim, bibendum montes, imperdiet cursus pulvinar tempus consectetur. 
                                </p>
                                <div class="mt-2">
                                    <button class="btn-2 px-3 py-2" uk-toggle="target:#reviewReport">
                                        Report this review
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="border-bottom">
                        <div class="d-flex flex-row p-3">
                            <div class="w-50 d-flex flex-column align-items-center ">
                                <img src="../../../assets/assets/Ellipse 181.svg" alt="" width="40">
                                <span class="fz-13 fw-bold mt-2">Manju Lakshmi EB</span>
                            </div>
                            <div class="d-flex flex-column ms-2">
                                <span>
                                    <img src="../../../assets/assets/rating.svg" alt="">
                                    <i class="fz-13 fw-bold ms-2">
                                        (4.5)
                                    </i> 
                                </span>
                                <span class="fz-13 fw-bold mt-3 mb-1">
                                    Lorem ipsum dolar sit amet
                                </span>
                                <p class="fz-13" style="line-height: 1.5rem;">
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. 
                                    Mauris enim pellentesque pulvinar et, feugiat. 
                                    Libero ligula leo ultrices fames nunc egestas. 
                                    Mi arcu at commodo volutpat. Tortor sagittis, nulla nisi, mattis dictumst. 
                                    Leo, enim enim, bibendum montes, imperdiet cursus pulvinar tempus consectetur. 
                                </p>
                                <div class="mt-2">
                                    <button class="btn-2 px-3 py-2">
                                        Report this review
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="border-bottom">
                        <div class="d-flex flex-row p-3">
                            <div class="w-50 d-flex flex-column align-items-center ">
                                <img src="../../../assets/assets/Ellipse 181.svg" alt="" width="40">
                                <span class="fz-13 fw-bold mt-2">Manju Lakshmi EB</span>
                            </div>
                            <div class="d-flex flex-column ms-2">
                                <span>
                                    <img src="../../../assets/assets/rating.svg" alt="">
                                    <i class="fz-13 fw-bold ms-2">
                                        (4.5)
                                    </i> 
                                </span>
                                <span class="fz-13 fw-bold mt-3 mb-1">
                                    Lorem ipsum dolar sit amet
                                </span>
                                <p class="fz-13" style="line-height: 1.5rem;">
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. 
                                    Mauris enim pellentesque pulvinar et, feugiat. 
                                    Libero ligula leo ultrices fames nunc egestas. 
                                    Mi arcu at commodo volutpat. Tortor sagittis, nulla nisi, mattis dictumst. 
                                    Leo, enim enim, bibendum montes, imperdiet cursus pulvinar tempus consectetur. 
                                </p>
                                <div class="mt-2">
                                    <button class="btn-2 px-3 py-2">
                                        Report this review
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="border-bottom">
                        <div class="d-flex flex-row p-3">
                            <div class="w-50 d-flex flex-column align-items-center ">
                                <img src="../../../assets/assets/Ellipse 181.svg" alt="" width="40">
                                <span class="fz-13 fw-bold mt-2">Manju Lakshmi EB</span>
                            </div>
                            <div class="d-flex flex-column ms-2">
                                <span>
                                    <img src="../../../assets/assets/rating.svg" alt="">
                                    <i class="fz-13 fw-bold ms-2">
                                        (4.5)
                                    </i> 
                                </span>
                                <span class="fz-13 fw-bold mt-3 mb-1">
                                    Lorem ipsum dolar sit amet
                                </span>
                                <p class="fz-13" style="line-height: 1.5rem;">
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. 
                                    Mauris enim pellentesque pulvinar et, feugiat. 
                                    Libero ligula leo ultrices fames nunc egestas. 
                                    Mi arcu at commodo volutpat. Tortor sagittis, nulla nisi, mattis dictumst. 
                                    Leo, enim enim, bibendum montes, imperdiet cursus pulvinar tempus consectetur. 
                                </p>
                                <div class="mt-2">
                                    <button class="btn-2 px-3 py-2">
                                        Report this review
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="border-bottom">
                        <div class="d-flex flex-row p-3">
                            <div class="w-50 d-flex flex-column align-items-center ">
                                <img src="../../../assets/assets/Ellipse 181.svg" alt="" width="40">
                                <span class="fz-13 fw-bold mt-2">Manju Lakshmi EB</span>
                            </div>
                            <div class="d-flex flex-column ms-2">
                                <span>
                                    <img src="../../../assets/assets/rating.svg" alt="">
                                    <i class="fz-13 fw-bold ms-2">
                                        (4.5)
                                    </i> 
                                </span>
                                <span class="fz-13 fw-bold mt-3 mb-1">
                                    Lorem ipsum dolar sit amet
                                </span>
                                <p class="fz-13" style="line-height: 1.5rem;">
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. 
                                    Mauris enim pellentesque pulvinar et, feugiat. 
                                    Libero ligula leo ultrices fames nunc egestas. 
                                    Mi arcu at commodo volutpat. Tortor sagittis, nulla nisi, mattis dictumst. 
                                    Leo, enim enim, bibendum montes, imperdiet cursus pulvinar tempus consectetur. 
                                </p>
                                <div class="mt-2">
                                    <button class="btn-2 px-3 py-2">
                                        Report this review
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="border-bottom">
                        <div class="d-flex flex-row p-3">
                            <div class="w-50 d-flex flex-column align-items-center ">
                                <img src="../../../assets/assets/Ellipse 181.svg" alt="" width="40">
                                <span class="fz-13 fw-bold mt-2">Manju Lakshmi EB</span>
                            </div>
                            <div class="d-flex flex-column ms-2">
                                <span>
                                    <img src="../../../assets/assets/rating.svg" alt="">
                                    <i class="fz-13 fw-bold ms-2">
                                        (4.5)
                                    </i> 
                                </span>
                                <span class="fz-13 fw-bold mt-3 mb-1">
                                    Lorem ipsum dolar sit amet
                                </span>
                                <p class="fz-13" style="line-height: 1.5rem;">
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. 
                                    Mauris enim pellentesque pulvinar et, feugiat. 
                                    Libero ligula leo ultrices fames nunc egestas. 
                                    Mi arcu at commodo volutpat. Tortor sagittis, nulla nisi, mattis dictumst. 
                                    Leo, enim enim, bibendum montes, imperdiet cursus pulvinar tempus consectetur. 
                                </p>
                                <div class="mt-2">
                                    <button class="btn-2 px-3 py-2">
                                        Report this review
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="border-bottom">
                        <div class="d-flex flex-row p-3">
                            <div class="w-50 d-flex flex-column align-items-center ">
                                <img src="../../../assets/assets/Ellipse 181.svg" alt="" width="40">
                                <span class="fz-13 fw-bold mt-2">Manju Lakshmi EB</span>
                            </div>
                            <div class="d-flex flex-column ms-2">
                                <span>
                                    <img src="../../../assets/assets/rating.svg" alt="">
                                    <i class="fz-13 fw-bold ms-2">
                                        (4.5)
                                    </i> 
                                </span>
                                <span class="fz-13 fw-bold mt-3 mb-1">
                                    Lorem ipsum dolar sit amet
                                </span>
                                <p class="fz-13" style="line-height: 1.5rem;">
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. 
                                    Mauris enim pellentesque pulvinar et, feugiat. 
                                    Libero ligula leo ultrices fames nunc egestas. 
                                    Mi arcu at commodo volutpat. Tortor sagittis, nulla nisi, mattis dictumst. 
                                    Leo, enim enim, bibendum montes, imperdiet cursus pulvinar tempus consectetur. 
                                </p>
                                <div class="mt-2">
                                    <button class="btn-2 px-3 py-2">
                                        Report this review
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="border-bottom">
                        <div class="d-flex flex-row p-3">
                            <div class="w-50 d-flex flex-column align-items-center ">
                                <img src="../../../assets/assets/Ellipse 181.svg" alt="" width="40">
                                <span class="fz-13 fw-bold mt-2">Manju Lakshmi EB</span>
                            </div>
                            <div class="d-flex flex-column ms-2">
                                <span>
                                    <img src="../../../assets/assets/rating.svg" alt="">
                                    <i class="fz-13 fw-bold ms-2">
                                        (4.5)
                                    </i> 
                                </span>
                                <span class="fz-13 fw-bold mt-3 mb-1">
                                    Lorem ipsum dolar sit amet
                                </span>
                                <p class="fz-13" style="line-height: 1.5rem;">
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. 
                                    Mauris enim pellentesque pulvinar et, feugiat. 
                                    Libero ligula leo ultrices fames nunc egestas. 
                                    Mi arcu at commodo volutpat. Tortor sagittis, nulla nisi, mattis dictumst. 
                                    Leo, enim enim, bibendum montes, imperdiet cursus pulvinar tempus consectetur. 
                                </p>
                                <div class="mt-2">
                                    <button class="btn-2 px-3 py-2">
                                        Report this review
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<div id="reviewReport" class="uk-flex-top" uk-modal>
    <div class="uk-modal-dialog uk-modal-body cardadding-body uk-margin-auto-vertical p-0">
        <button class="uk-modal-close-default" type="button" uk-close></button>
        <div class="col-12 row mx-0 bc-F4F3F0 px-3 py-2">
            <div class="d-flex flex-column px-0">
                <span class="fz-20 fw-bold">Report this Review</span>
            </div> 
        </div>
        <div class="body-uk py-2 px-3">
            <div class="">
                <span class="fz-13" style="color: #3D3C3C; line-height: 1rem;">
                    If you find abusive. This post will reported to our moderation team 
                </span>
            </div>
            <div class="col-12 row mx-0 px-0 mt-3">
                <div class="checkbox p-3">
                    <div class="d-flex flex-row align-items-center">
                        <input class="form-check-input mt-0" type="radio" value="" id="flexCheckChecked" checked>
                        <span class="ms-2 fz-13">Vulgure Content</span>
                    </div>
                </div>
                <div class="checkbox p-3 mt-2">
                    <div class="d-flex flex-row align-items-center">
                        <input class="form-check-input mt-0" type="radio" value="" id="flexCheckChecked" checked>
                        <span class="ms-2 fz-13">Romour Content</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 row mx-0 mt-3 pt-4 pb-2">
            <div class="d-flex justify-content-between">
                <button class="cancel-btn fz-13 fw-bold px-3 py-3 w-50">Cancel</button>
                <button class="update-btn ms-2 px-3 py-3 w-50">Yes, Confirm</button>
            </div>
        </div>
   </div>
</div>
