<app-sidraheader style="position: fixed;width: 100%;top: 0;left: 0;z-index: 999;"></app-sidraheader>
<app-menu-header></app-menu-header>
<div class="conatiner-fluid py-5"style="background-color: #F4F3F0;">
    <div class="col-12 row mx-0 d-flex justify-content-center">
         <div class="px-4 py-5" style="width: 400px;background-color: white;">
                <div class=" w-100 d-flex justify-content-center">
                    <img src="../../../assets/icons/giftpic.svg" alt="" width="50">
                </div>
                <div class="py-2 d-flex justify-content-center">
                    <span class="fz-20 fw-bold ">
                        Refer a friend &
                        get a coupon code!
                    </span>
                </div>
                <div class="py-2 d-flex justify-content-center px-3">
                    <span class="fz-12" style="color: #585858;line-height: 1.5;">
                        Invite your friends to SidraBazar and you will get a 
                        coupon code once they make their first purchase!
                    </span>
                </div>
                <div class="d-flex justify-content-center" >
                    <div class="d-flex justify-content-between align-items-center px-2 py-1 mx-2 w-75" style="border: 1.5px solid #E6E6E6;border-radius: 5px;">
                        <span class="fw-bold fz-11" style="color: #585858;line-height: 1.5;">
                            {{userData?.username}}
                        </span>
                        <span  uk-tooltip="title: copied;" class="fz-13 fw-500  copy-box cursor d-flex justify-content-center align-items-center" style="color: white;" (click)="copyTextdata()">
                              <img src="../../../assets/icons/content_copy.svg" alt="" width="14">
                        </span>
                    </div>
                </div>
                <div class=" d-flex justify-content-center flex-row mt-3">
                    <img class="cursor" src="../../../assets/icons/face.svg" alt="" width="35" (click)="clickLinkShare('face')">
                    <img class="ms-2 cursor" src="../../../assets/icons/whatsappshare.svg" alt="" width="35" (click)="clickLinkShare('whats')">
                    <img class="ms-2 cursor" src="../../../assets/icons/twittershare.svg" alt="" width="35" (click)="clickLinkShare('twi')">
                    <img  class=" ms-2 cursor "src="../../../assets/icons/emailshare.svg" alt="" width="35" (click)="emailshare()">
                </div>
                <div class="d-flex justify-content-center mt-2 cursor" uk-toggle="target:#moreInfo">
                    <span class="fz-12" style="color: #FF9900;">
                        Terms and conditions
                    </span>
                </div>
         </div> 
    </div>
</div>

<div id="moreInfo" class="uk-flex-top" uk-modal>
    <div>
        <button class="uk-modal-close-default" type="button" uk-close></button>
    </div>
    <div class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical p-0" style="width: 800px;">
        <button class="uk-modal-close-default" type="button" uk-close></button>
        <div class="col-12 row mx-0  header-c " style="background-color: #F4F3F0;">
           <div class="py-3 px-4">
                <span class="fz-18 fw-bold">
                    Delivery System
                </span>
           </div>
            <div class="col-12 p-0" >
                <div class="bg-white ms-1 p-4" style="height: 100%;">
                    <div class="scroll">
                        <div class="">
                            <span class="fz-16 fw-bold">
                                Sidra Bazar using currently 4 types of delivery options.                            </span>
                            <div class="mt-2">
                                <p class="fz-12 mt-2" style="line-height: 1.3rem;">
                                    E-commerce delivery involves various steps, such as order processing, packing, shipping, and delivery. Once an order is placed online, the retailer or e-commerce platform must process the order, verify payment, and prepare the item for shipping. The item is then packed and shipped using various modes of transportation, such as air, sea, or ground.

                                    The delivery process can vary depending on the retailer or e-commerce platform, and can include options such as standard delivery, express delivery, and time-slot based delivery. Customers may be able to track their orders and receive notifications regarding the status of their delivery. The delivery process can vary depending on the retailer or e-commerce platform, and can include options such as standard delivery, express delivery, and time-slot based delivery. Customers may be able to track their orders and receive notifications regarding the status of their delivery.                                </p>
                            </div>
                         </div>
                         <div class="">
                            <span class="fz-16 fw-bold">
                                Guarantees immediate delivery of a product or service, usually within 1 hour.                            </span>
                            <div class="mt-2">
                                <p class="fz-12 mt-2" style="line-height: 1.3rem;">
                                    E-commerce delivery involves various steps, such as order processing, packing, shipping, and delivery. Once an order is placed online, the retailer or e-commerce platform must process the order, verify payment, and prepare the item for shipping. The item is then packed and shipped using various modes of transportation, such as air, sea, or ground.

                                    The delivery process can vary depending on the retailer or e-commerce platform, and can include options such as standard delivery, express delivery, and time-slot based delivery. Customers may be able to track their orders and receive notifications regarding the status of their delivery. The delivery process can vary depending on the retailer or e-commerce platform, and can include options such as standard delivery, express delivery, and time-slot based delivery. Customers may be able to track their orders and receive notifications regarding the status of their delivery.                                </p>
                            </div>
                         </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-footer></app-footer>