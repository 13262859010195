
    <!-- Popular Category -->
    <app-popular-category
        group="Around the world"
        [count]="count"
        [popular_category]="popular_category"
    >
    </app-popular-category>
    <!-- Ends of Popular Category -->

    <div class="container-fluid mt-3 p-3 w-100">
        <h4 class="fw-bold min-fit-width col-12 mb-4 ms-2 ms-md-0 ps-3 fz-22">Recommanded for you</h4>
        <div class="row px-3 gx-2">
            <div class="col-md-4 col-12">
                <div class="bg-gray-200 special-card">
                    <label class="special-card__title" for="">New Arrivals</label>
                    <div class="d-flex w-100">
                        <div class="col-4">
                            <div class="special-card__img">
                                <img  src="../../../assets/assets/product-special.png" alt="">
                            </div>
                            <label class="special-card__price" for="">AED 4199.00</label>
                        </div>
                        <div class="col-4">
                            <div class="special-card__img">
                                <img  src="../../../assets/assets/product-special.png" alt="">
                            </div>
                            <label class="special-card__price" for="">AED 4199.00</label>
                        </div>
                        <div class="col-4">
                            <div class="special-card__img">
                                <img  src="../../../assets/assets/product-special.png" alt="">
                            </div>
                            <label class="special-card__price" for="">AED 4199.00</label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-4 col-12">
                <div class="bg-gray-200 special-card">
                    <label class="special-card__title" for="">New Arrivals</label>
                    <div class="d-flex w-100">
                        <div class="col-4">
                            <div class="special-card__img">
                                <img  src="../../../assets/assets/product-special.png" alt="">
                            </div>
                            <label class="special-card__price" for="">AED 4199.00</label>
                        </div>
                        <div class="col-4">
                            <div class="special-card__img">
                                <img  src="../../../assets/assets/product-special.png" alt="">
                            </div>
                            <label class="special-card__price" for="">AED 4199.00</label>
                        </div>
                        <div class="col-4">
                            <div class="special-card__img">
                                <img  src="../../../assets/assets/product-special.png" alt="">
                            </div>
                            <label class="special-card__price" for="">AED 4199.00</label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-4 col-12">
                <div class="bg-gray-200 special-card">
                    <label class="special-card__title" for="">New Arrivals</label>
                    <div class="d-flex w-100">
                        <div class="col-4">
                            <div class="special-card__img">
                                <img  src="../../../assets/assets/product-special.png" alt="">
                            </div>
                            <label class="special-card__price" for="">AED 4199.00</label>
                        </div>
                        <div class="col-4">
                            <div class="special-card__img">
                                <img  src="../../../assets/assets/product-special.png" alt="">
                            </div>
                            <label class="special-card__price" for="">AED 4199.00</label>
                        </div>
                        <div class="col-4">
                            <div class="special-card__img">
                                <img  src="../../../assets/assets/product-special.png" alt="">
                            </div>
                            <label class="special-card__price" for="">AED 4199.00</label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>  
                    

    <!-- product slider Section -->
    <div class="container-fluid d-flex flex-wrap mt-3 p-3 w-100">
        <h4 class="fw-bold min-fit-width col-12 mb-4 ms-2 ms-md-0 ps-3 fz-22">Classic Watches</h4>
        <div class="col-12 multi-slider">
            <div class="uk-position-relative uk-visible-toggle uk-dark px-10px" tabindex="-1" uk-slider>
                <ul class="uk-slider-items uk-child-width-1-1 uk-child-width-1-2@sm uk-child-width-1-2@s uk-child-width-1-3@m uk-child-width-1-4@l uk-child-width-1-5@xl">
                    <li class="pb-3 border-0 mx-auto d-flex">
                        <div class="ps-1 col-6">
                            <img class="object-fit w-100 world-product" src="../../assets/assets/watches.png">
                        </div>
                        <div class="ps-1 col-6">
                            <img class="object-fit w-100 world-product" src="../../assets/assets/watches1.png">
                        </div>
                    </li>
                    <li class="pb-3 border-0 mx-auto d-flex">
                        <div class="ps-1 col-6">
                            <img class="object-fit w-100 world-product" src="../../assets/assets/watches.png">
                        </div>
                        <div class="ps-1 col-6">
                            <img class="object-fit w-100 world-product" src="../../assets/assets/watches1.png">
                        </div>
                    </li>
                    <li class="pb-3 border-0 mx-auto d-flex">
                        <div class="ps-1 col-6">
                            <img class="object-fit w-100 world-product" src="../../assets/assets/watches.png">
                        </div>
                        <div class="ps-1 col-6">
                            <img class="object-fit w-100 world-product" src="../../assets/assets/watches1.png">
                        </div>
                    </li>
                    <li class="pb-3 border-0 mx-auto d-flex">
                        <div class="ps-1 col-6">
                            <img class="object-fit w-100 world-product" src="../../assets/assets/watches.png">
                        </div>
                        <div class="ps-1 col-6">
                            <img class="object-fit w-100 world-product" src="../../assets/assets/watches1.png">
                        </div>
                    </li>
                    <li class="pb-3 border-0 mx-auto d-flex">
                        <div class="ps-1 col-6">
                            <img class="object-fit w-100 world-product" src="../../assets/assets/watches.png">
                        </div>
                        <div class="ps-1 col-6">
                            <img class="object-fit w-100 world-product" src="../../assets/assets/watches1.png">
                        </div>
                    </li>
                    <li class="pb-3 border-0 mx-auto d-flex">
                        <div class="ps-1 col-6">
                            <img class="object-fit w-100 world-product" src="../../assets/assets/watches.png">
                        </div>
                        <div class="ps-1 col-6">
                            <img class="object-fit w-100 world-product" src="../../assets/assets/watches1.png">
                        </div>
                    </li>
                </ul>
                <a class="uk-position-center-left uk-position-small" href="#" uk-slidenav-previous uk-slider-item="previous"></a>
                <a class="uk-position-center-right uk-position-small" href="#" uk-slidenav-next uk-slider-item="next"></a>
            </div>
        </div>
    </div>

    <!-- product slider Section -->
    <div class="container-fluid d-flex flex-wrap mt-3 p-3 w-100">
        <h4 class="fw-bold min-fit-width col-12 mb-4 ms-2 ms-md-0 ps-3 fz-22">What is New !</h4>
        <div class="col-12 multi-slider">
            <div class="uk-position-relative uk-visible-toggle uk-dark px-10px" tabindex="-1" uk-slider>
                <ul class="uk-slider-items uk-child-width-1-1 uk-child-width-1-2@sm uk-child-width-1-2@s uk-child-width-1-3@m uk-child-width-1-4@l uk-child-width-1-5@xl">
                    <li class="pb-3 border-0 mx-auto d-flex">
                        <div class="ps-1 col-6">
                            <img class="object-fit w-100 world-product" src="../../assets/assets/jewelry.png">
                        </div>
                        <div class="ps-1 col-6">
                            <img class="object-fit w-100 world-product" src="../../assets/assets/choclate.png">
                        </div>
                    </li>
                    <li class="pb-3 border-0 mx-auto d-flex">
                        <div class="ps-1 col-6">
                            <img class="object-fit w-100 world-product" src="../../assets/assets/perfume.png">
                        </div>
                        <div class="ps-1 col-6">
                            <img class="object-fit w-100 world-product" src="../../assets/assets/choclate.png">
                        </div>
                    </li>
                    <li class="pb-3 border-0 mx-auto d-flex">
                        <div class="ps-1 col-6">
                            <img class="object-fit w-100 world-product" src="../../assets/assets/jewelry.png">
                        </div>
                        <div class="ps-1 col-6">
                            <img class="object-fit w-100 world-product" src="../../assets/assets/choclate.png">
                        </div>
                    </li>
                    <li class="pb-3 border-0 mx-auto d-flex">
                        <div class="ps-1 col-6">
                            <img class="object-fit w-100 world-product" src="../../assets/assets/perfume.png">
                        </div>
                        <div class="ps-1 col-6">
                            <img class="object-fit w-100 world-product" src="../../assets/assets/choclate.png">
                        </div>
                    </li>
                    <li class="pb-3 border-0 mx-auto d-flex">
                        <div class="ps-1 col-6">
                            <img class="object-fit w-100 world-product" src="../../assets/assets/jewelry.png">
                        </div>
                        <div class="ps-1 col-6">
                            <img class="object-fit w-100 world-product" src="../../assets/assets/choclate.png">
                        </div>
                    </li>
                    <li class="pb-3 border-0 mx-auto d-flex">
                        <div class="ps-1 col-6">
                            <img class="object-fit w-100 world-product" src="../../assets/assets/perfume.png">
                        </div>
                        <div class="ps-1 col-6">
                            <img class="object-fit w-100 world-product" src="../../assets/assets/choclate.png">
                        </div>
                    </li>
                </ul>
                <a class="uk-position-center-left uk-position-small" href="#" uk-slidenav-previous uk-slider-item="previous"></a>
                <a class="uk-position-center-right uk-position-small" href="#" uk-slidenav-next uk-slider-item="next"></a>
            </div>
        </div>
    </div>


    <div class="container-fluid d-flex flex-wrap mt-3 p-3 w-100">
        <h4 class="fw-bold min-fit-width col-12 mb-4 ms-2 ms-md-0 ps-3 fz-22">Stared products</h4>
        <div class="col-12 multi-slider">
            <div class="uk-position-relative uk-visible-toggle uk-dark px-10px" tabindex="-1" uk-slider>
                <ul class="uk-slider-items uk-child-width-1-1 uk-child-width-1-2@sm uk-child-width-1-2@s uk-child-width-1-3@m uk-child-width-1-4@l uk-child-width-1-5@xl">
                    <li class="pb-3 border-0 mx-auto d-flex">
                        <div class="ps-1 col-12">
                            <img class="object-fit w-100" src="../../assets/assets/stared-product.png">
                        </div>
                    </li>
                    <li class="pb-3 border-0 mx-auto d-flex">
                        <div class="ps-1 col-12">
                            <img class="object-fit w-100" src="../../assets/assets/stared-product.png">
                        </div>
                    </li>
                    <li class="pb-3 border-0 mx-auto d-flex">
                        <div class="ps-1 col-12">
                            <img class="object-fit w-100" src="../../assets/assets/stared-product.png">
                        </div>
                    </li>
                    <li class="pb-3 border-0 mx-auto d-flex">
                        <div class="ps-1 col-12">
                            <img class="object-fit w-100" src="../../assets/assets/stared-product.png">
                        </div>
                    </li>
                    <li class="pb-3 border-0 mx-auto d-flex">
                        <div class="ps-1 col-12">
                            <img class="object-fit w-100" src="../../assets/assets/stared-product.png">
                        </div>
                    </li>
                    <li class="pb-3 border-0 mx-auto d-flex">
                        <div class="ps-1 col-12">
                            <img class="object-fit w-100" src="../../assets/assets/stared-product.png">
                        </div>
                    </li>
                </ul>
                <a class="uk-position-center-left uk-position-small" href="#" uk-slidenav-previous uk-slider-item="previous"></a>
                <a class="uk-position-center-right uk-position-small" href="#" uk-slidenav-next uk-slider-item="next"></a>
            </div>
        </div>
    </div>

    <!-- product slider Section -->
    <div class="container-fluid d-flex flex-wrap mt-3 p-3 w-100">
        <h4 class="fw-bold min-fit-width col-12 mb-4 ms-2 ms-md-0 ps-3 fz-22">Reccomanded Product</h4>
        <div class="col-12 multi-slider">
            <div class="uk-position-relative uk-visible-toggle uk-dark px-10px" tabindex="-1" uk-slider>
                <ul class="uk-slider-items uk-child-width-1-1 uk-child-width-1-2@sm uk-child-width-1-2@s uk-child-width-1-3@m uk-child-width-1-4@l uk-child-width-1-5@xl">
                    <li class="pb-3 border-0 mx-auto d-flex">
                        <div class="ps-1 col-6">
                            <img class="object-fit w-100 world-product" src="../../assets/assets/jewelry.png">
                        </div>
                        <div class="ps-1 col-6">
                            <img class="object-fit w-100 world-product" src="../../assets/assets/choclate.png">
                        </div>
                    </li>
                    <li class="pb-3 border-0 mx-auto d-flex">
                        <div class="ps-1 col-6">
                            <img class="object-fit w-100 world-product" src="../../assets/assets/perfume.png">
                        </div>
                        <div class="ps-1 col-6">
                            <img class="object-fit w-100 world-product" src="../../assets/assets/choclate.png">
                        </div>
                    </li>
                    <li class="pb-3 border-0 mx-auto d-flex">
                        <div class="ps-1 col-6">
                            <img class="object-fit w-100 world-product" src="../../assets/assets/jewelry.png">
                        </div>
                        <div class="ps-1 col-6">
                            <img class="object-fit w-100 world-product" src="../../assets/assets/choclate.png">
                        </div>
                    </li>
                    <li class="pb-3 border-0 mx-auto d-flex">
                        <div class="ps-1 col-6">
                            <img class="object-fit w-100 world-product" src="../../assets/assets/perfume.png">
                        </div>
                        <div class="ps-1 col-6">
                            <img class="object-fit w-100 world-product" src="../../assets/assets/choclate.png">
                        </div>
                    </li>
                    <li class="pb-3 border-0 mx-auto d-flex">
                        <div class="ps-1 col-6">
                            <img class="object-fit w-100 world-product" src="../../assets/assets/jewelry.png">
                        </div>
                        <div class="ps-1 col-6">
                            <img class="object-fit w-100 world-product" src="../../assets/assets/choclate.png">
                        </div>
                    </li>
                    <li class="pb-3 border-0 mx-auto d-flex">
                        <div class="ps-1 col-6">
                            <img class="object-fit w-100 world-product" src="../../assets/assets/perfume.png">
                        </div>
                        <div class="ps-1 col-6">
                            <img class="object-fit w-100 world-product" src="../../assets/assets/choclate.png">
                        </div>
                    </li>
                </ul>
                <a class="uk-position-center-left uk-position-small" href="#" uk-slidenav-previous uk-slider-item="previous"></a>
                <a class="uk-position-center-right uk-position-small" href="#" uk-slidenav-next uk-slider-item="next"></a>
            </div>
        </div>
    </div>

    <div class="container-fluid d-flex flex-wrap mt-3 p-3 w-100">
        <h4 class="fw-bold min-fit-width col-12 mb-4 ms-2 ms-md-0 ps-3 fz-22">Recommended for you</h4>
        <div class="col-12 row px-3 gx-3">
            <div class="col-lg-3 col-md-4 col-6 mb-3">
                <img src="../../../assets/assets/world-offer1.png" alt="">
            </div>
            <div class="col-lg-3 col-md-4 col-6 mb-3">
                <img src="../../../assets/assets/world-offer2.png" alt="">
            </div>
            <div class="col-lg-3 col-md-4 col-6 mb-3">
                <img src="../../../assets/assets/world-offer3.png" alt="">
            </div>
            <div class="col-lg-3 col-md-4 col-6 mb-3">
                <img src="../../../assets/assets/word-offer4.png" alt="">
            </div>
            <div class="col-lg-3 col-md-4 col-6 mb-3">
                <img src="../../../assets/assets/world-offer1.png" alt="">
            </div>
            <div class="col-lg-3 col-md-4 col-6 mb-3">
                <img src="../../../assets/assets/world-offer2.png" alt="">
            </div>
            <div class="col-lg-3 col-md-4 col-6 mb-3">
                <img src="../../../assets/assets/world-offer3.png" alt="">
            </div>
            <div class="col-lg-3 col-md-4 col-6 mb-3">
                <img src="../../../assets/assets/word-offer4.png" alt="">
            </div>
        </div>
    </div>

    <!-- product slider Section -->
    <div class="container-fluid d-flex flex-wrap mt-3 p-3 w-100">
        <h4 class="fw-bold min-fit-width col-12 mb-4 ms-2 ms-md-0 ps-3 fz-22">Classic Watches</h4>
        <div class="col-12 multi-slider">
            <div class="uk-position-relative uk-visible-toggle uk-dark px-10px" tabindex="-1" uk-slider>
                <ul class="uk-slider-items uk-child-width-1-1 uk-child-width-1-2@sm uk-child-width-1-2@s uk-child-width-1-3@m uk-child-width-1-4@l uk-child-width-1-5@xl">
                    <li class="pb-3 border-0 mx-auto d-flex">
                        <div class="ps-1 col-6">
                            <img class="object-fit w-100 world-product" src="../../assets/assets/watches.png">
                        </div>
                        <div class="ps-1 col-6">
                            <img class="object-fit w-100 world-product" src="../../assets/assets/watches1.png">
                        </div>
                    </li>
                    <li class="pb-3 border-0 mx-auto d-flex">
                        <div class="ps-1 col-6">
                            <img class="object-fit w-100 world-product" src="../../assets/assets/watches.png">
                        </div>
                        <div class="ps-1 col-6">
                            <img class="object-fit w-100 world-product" src="../../assets/assets/watches1.png">
                        </div>
                    </li>
                    <li class="pb-3 border-0 mx-auto d-flex">
                        <div class="ps-1 col-6">
                            <img class="object-fit w-100 world-product" src="../../assets/assets/watches.png">
                        </div>
                        <div class="ps-1 col-6">
                            <img class="object-fit w-100 world-product" src="../../assets/assets/watches1.png">
                        </div>
                    </li>
                    <li class="pb-3 border-0 mx-auto d-flex">
                        <div class="ps-1 col-6">
                            <img class="object-fit w-100 world-product" src="../../assets/assets/watches.png">
                        </div>
                        <div class="ps-1 col-6">
                            <img class="object-fit w-100 world-product" src="../../assets/assets/watches1.png">
                        </div>
                    </li>
                    <li class="pb-3 border-0 mx-auto d-flex">
                        <div class="ps-1 col-6">
                            <img class="object-fit w-100 world-product" src="../../assets/assets/watches.png">
                        </div>
                        <div class="ps-1 col-6">
                            <img class="object-fit w-100 world-product" src="../../assets/assets/watches1.png">
                        </div>
                    </li>
                    <li class="pb-3 border-0 mx-auto d-flex">
                        <div class="ps-1 col-6">
                            <img class="object-fit w-100 world-product" src="../../assets/assets/watches.png">
                        </div>
                        <div class="ps-1 col-6">
                            <img class="object-fit w-100 world-product" src="../../assets/assets/watches1.png">
                        </div>
                    </li>
                </ul>
                <a class="uk-position-center-left uk-position-small" href="#" uk-slidenav-previous uk-slider-item="previous"></a>
                <a class="uk-position-center-right uk-position-small" href="#" uk-slidenav-next uk-slider-item="next"></a>
            </div>
        </div>
    </div>


    <div class="py-3 w-100"></div>