



<!-- <div  *ngIf="isLoading" class="container-fluid  loading-overlay d-flex justify-content-center align-items-center" style="height:100%">
    <img src="../../../../assets/images/New-anime2.gif" alt="" width="80">
</div> -->
<div class="container-fluid py-0 px-0" style="background-color: #F4F3F0;">
    <div class="col-12 mx-0 py-2 d-flex justify-content-between px-3 align-items-center" style="background-color: #323031;">
        <img src="../../../../assets/icons/Sidra Logo white-16 (1).svg" class="cursor" alt="" width="70" [routerLink]="['/']">
        <span class="fz-13 fw-500 cursor" style="color: white;" [routerLink]="['/']" routerLinkActive="router-link-active" >
            Return to Home page
        </span>
    </div> 
    <div class="col-12 d-flex justify-content-center align-items-center py-5" >
        <div class="login-page d-flex flex-wrap bw d-flex flex-column justify-content-center">
            <div class="d-flex justify-content-center">
                <span class="fz-22 fw-500">Login to your account</span>
            </div>
            <div class="d-flex justify-content-center my-0 py-0">
                <span class="fz-13 c-585858 mt-2">Let's login to get started</span>
            </div> 
            <div class="mt-4 mb-2">
                <input type="email" class="form-control fz-12 h-55" *ngIf="isNumber==false" [(ngModel)]="email" placeholder="Email">
                <div class="form-control d-flex flex-row px-0 py-0" *ngIf="isNumber==true" style="position: relative;border-radius: 5px;">
                    <select class="form-select w-25 pe-1 ps-2" style="border-radius: 5px !important; outline: none; box-shadow: none;border: none;height: 38px;" [(ngModel)]="country">
                        <option *ngFor="let con of contry_code_list" [value]="con.code" class="fz-13" style="color: black;">{{con.code}}</option>
                    </select> 
                    <input class="fz-12 w-100" list="datalistOptions" maxlength="15" type="text" [(ngModel)]="contact" placeholder="558678579" style=" outline: none; border: none;border-radius: 5px;" >
                </div>
                <div class="d-flex justify-content-end mt-1" *ngIf="isNumber==false" (click)="changeNumberEmail('number')">
                    <span  class="c-FF9900 fz-11 cursor">login with phone number</span>
                </div>
                <div class="d-flex justify-content-end mt-1" *ngIf="isNumber==true" (click)="changeNumberEmail('email')">
                    <span  class="c-FF9900 fz-11 cursor">login with email</span>
                </div>
            </div> 
            <div class="">
                <mat-form-field appearance="fill" class="w-100 form-control  f-12">
                    <!-- <mat-label>Enter your password</mat-label> -->
                    <input class="fz-12 fw-500" [(ngModel)]="pass" matInput [type]="hide ? 'password' : 'text'" placeholder="Enter the Password" style="font-weight: 500 !important;color: #212529 !important;" (focus)="inputTouched = true">
                    <button class="fz-12 eye-icon" mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                        <mat-icon class="">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                    </button> 
                </mat-form-field>
                <!-- <input type="password" class="form-control fz-12 h-55" formControlName="password" placeholder="Password"> -->
            </div> 
            <div class="d-flex justify-content-end mt-2 mb-3">
                <span class="fz-14 fw-bold cursor" [routerLink]="['/auth/forgot_password']" routerLinkActive="router-link-active" >Forgot Password ?</span>
            </div> 
            <button type="submit" class="login-btn fz-15 fw-400" (click)="OnLoginSubmit()" *ngIf="isLoading==false">Login to your Account</button>
            <button type="submit" class="login-btn fz-15 fw-400 d-flex align-items-center align-items-center" *ngIf="isLoading==true">
                <div class="spinner-border text-light" role="status">
                    <span class="visually-hidden">Loading...</span>
                </div> 
            </button> 
            <div class="mt-1 fz-12 l-h d-flex justify-content-center">
                <span class="fz-12 l-h"></span> <i class="c-FF9900 fz-12 mx-1 cursor" [routerLink]="['/loginwithotp']" routerLinkActive="router-link-active" >Login with otp</i>
            </div> 
            <div class="mt-3 c-5F5F61 fz-12 l-h">
                <span class="c-5F5F61 fz-12 l-h">By continuing you agree to our</span> 
                <i class="c-FF9900 fz-12 mx-1 cursor" [routerLink]="['/termsanduse']" routerLinkActive="router-link-active" >Terms and Conditions</i>
                 and our  
                 <i class="c-FF9900 fz-12 cursor" [routerLink]="['/genaral-policy']"> Privacy Policy.</i> 
            </div>
            <hr class="mb-2">
            <div class="">
                <span class="fz-13 fw-500 c-5F5F61">Dont have an Account? <i class="fz-13 fw-500 c-FF9900 ms-1 cursor" [routerLink]="['/auth/signup']" routerLinkActive="router-link-active">Create an Account</i></span>
            </div>
        </div>
    </div>
</div>