<div class="container-fluid bgw px-sm-5 px-0">
    <div class=" row mx-0 py-2 px-sm-0 px-2 d-flex align-items-center justify-content-between">
        <div class=" col-6 logo px-0">
            <img src="../../../assets/icons/sidra_logo.png" alt="" [routerLink]="['/']">
        </div>
        <div class="col-6 d-flex align-items-center justify-content-end px-0">
            <div class="d-none d-md-block">
                <span class="career cursor  fz-15" [routerLinkActive]="'career-active'"
                    [routerLink]="['/career']">Career</span>
                <span class="aboutus ms-lg-5 ms-3 cursor  fz-15" [routerLinkActive]="'aboutus-active'"
                    [routerLink]="['/aboutus']">About Us</span>
                <span class="aboutus ms-lg-5 ms-3 cursor fz-15" [routerLinkActive]="'aboutus-active'"
                    [class.aboutus-active-custom]="isSellWithUsRegistration" [routerLink]="['/sell-withus']">
                    Sell on Sidrabazar
                </span>
            </div>

            <div class="uk-inline d-flex d-md-none justify-content-end align-items-end">
                <button class="uk-button uk-button-default pe-0" type="button"><span uk-icon="menu"></span></button>
                <div uk-dropdown="pos: bottom-center">
                    <ul class="uk-nav uk-dropdown-nav">
                        <li class="mb-2"><span class="career cursor  fz-12" [routerLinkActive]="'career-active'"
                                [routerLink]="['/career']">Career</span></li>
                                <hr>
                        <li class="mb-2"><span class="aboutus  cursor  fz-12" [routerLinkActive]="'aboutus-active'"
                                [routerLink]="['/aboutus']">About Us</span></li>
                                <hr>
                        <li> <span class="aboutus  cursor fz-12" [routerLinkActive]="'aboutus-active'"
                                [class.aboutus-active-custom]="isSellWithUsRegistration"
                                [routerLink]="['/sell-withus']">
                                Sell on Sidrabazar</span>
                        </li>
                    </ul>
                </div>
            </div>

        </div>
    </div>
</div>