<!-- product slider Section -->
<div class="container-fluid mt-3 p-md-3 p-3 py-3 px-md-4 w-100" [ngClass]="{'life-style-gradient': group ==='smart', 'essential-gradient' : group === 'groceries', 'bulk-gradient': group ==='bulk', 'around-world-gradient': group === 'home_living'}">
    <div class="col-12 multi-slider">
        <div class="uk-position-relative uk-visible-toggle uk-dark px-md-2 pe-0 p-0" tabindex="-1" uk-slider="sets: true">
            <ul [dir]="directions" class="uk-slider-items uk-child-width-1-1 uk-child-width-1-2@sm uk-child-width-1-2@s uk-child-width-1-3@m uk-child-width-1-4@l uk-child-width-1-4@xl">
                <li class="pb-3 border-0 d-flex slider-item" *ngFor="let deal of parent">
                    <app-deal-card-one [parent]="deal">
                        <!-- deals card -->
                    </app-deal-card-one>
                </li>
            </ul>
            <a class="uk-position-center-left uk-position-small" href="#" uk-slidenav-previous uk-slider-item="previous"></a>
            <a class="uk-position-center-right uk-position-small" href="#" uk-slidenav-next uk-slider-item="next"></a>
        </div>
    </div>
</div>
<!-- End product slider Section -->