
<div class="py-1 px-3 box-1  shadow" style="position:fixed;width: 100%;top: 0;">

    <div class=" d-flex justify-content-between ">

        <div class="">

            <a [routerLink]="['/']">

                <img src="../../../assets/assets/Sidra Logo 2 hori-15 1.svg" width="210" alt="">

            </a>

        </div>

        <div class=" d-flex align-items-center justify-content-end me-sm-3 me-0 ms-3">

            <div class="iconborder d-flex justify-content-center align-items-center  me-2">
                <a class="atag " href="https://twitter.com/SidraBazar">
                    <span class="fz-15" uk-icon="twitter"></span>
                </a>
            </div>

            <div class="iconborder d-flex justify-content-center align-items-center me-2">
                <a class="atag"href="https://www.facebook.com/profile.php?id=100090996295975">
                    <span class="fz-15" uk-icon="facebook"></span>
                </a>
            </div>

            <div class="iconborder d-flex justify-content-center align-items-center  me-2">
                <a class="atag" href="https://api.whatsapp.com/send?phone=971504459003">
                    <span class="fz-15" uk-icon="whatsapp"></span>
                </a>
            </div>

            <div class="iconborder d-flex justify-content-center align-items-center  ">
                <a class="atag" href="https://www.instagram.com/sidrabazar/">
                    <span   class="fz-15" uk-icon="instagram"></span>
                </a>
            </div>
        </div>

    </div>

</div>


<div class="main-container px-3 py-sm-5 py-3">

    <div class="px-sm-5 px-2 pt-4 pb-5 box mt-5">

        <h4 class="heading-1 mb-2">Privacy Policy</h4>

        <span class="date">Last updated: May 11, 2023</span>

        <p class="paragraph lh-sm my-3">We understand that you have a say in how personal information about you

            is used and shared, and we

            value your trust in us to do so wisely and intelligently.

            This Privacy Policy explains how we will handle your personal data, such as payment information and

            other information we collect from you or other sources or that you provide to us ("Information"), in

            connection with your access to and www. Sidrabazar.com' and/or the Sidrabazar mobile application

            (collectively, the "Platform"), services, and applications (collectively, the "Services").

            We recognised the value you place on the Information, and we are dedicated to safeguarding and

            respecting your personal information. Please read the following carefully to learn about our data

            handling methods.

            By using our Services, you consent to your information being handled in line with this Privacy

            Policy.</p>

        <p class="paragraph lh-sm my-3">

            The terms "we," "our," and "us" (or variations thereof) in this Privacy Policy relate to Sidrabazar

            E-commerce, which is owned by CLUSTER STAR LLC. "User" or "you" (or similar terms) refer to you as

            an individual or legal entity, depending on the situation.

            This Privacy Policy explains our policies and practices for collecting, using, and disclosing Your

            information while using the Service, as well as Your privacy rights and how the law protects you.

            Your personal information is used to offer and improve the Service.

            You agree to the collection and use of information in accordance with this Privacy Policy by using

            the Service.

        </p>




        <span class="heading-2">DATA POLICY</span>

        <p class="paragraph lh-sm my-2 mt-3">

            We see it as part of our social responsibility to preserve data privacy rights.

            We need to collect and process data as part of our operations. Any offline or online data that

            identifies a person, such as names, addresses, usernames and passwords, digital footprints, photos,

            social security numbers, financial data, and so on, is included in this data.

            We collect this data in an open and transparent manner, with the full consent and knowledge of all

            parties involved.

            Following are the regulations that apply after we get this information.

        </p>




        <span class="heading-2"><u>DATA COLLECTED FROM CUSTOMERS</u></span>

        <p class="paragraph lh-sm my-2 mt-3">We may collect and handle the following information about you:

        </p>

        <p class="paragraph lh-sm my-3">a. Data you provide by filling out forms on our Platform, such as data

            provided when registering to

            use our Platform and other co-registrations (e.g., online media logins), subscribing to our

            Services, uploading material, or referencing further administrations;</p>

        <p class="paragraph lh-sm my-3">b. Usage Data may include information such as Your Device's Internet

            Protocol address (IP Address),

            browser type, browser version, the pages of our Service that You visit, the time and date of your

            visit, the time spent on those pages, unique device identifiers, and other diagnostic data, as well

            as data about your overall internet usage (for example, by utilising technology that stores data on

            or accesses your device, such as Cookies, Tracking pixels, web beacons, and other diagnostic data),

            as well as data about your overall</p>

        <p class="paragraph lh-sm my-3">c. We may collect certain information automatically when You access the

            Service via or through a

            mobile device, including, but not limited to, the type of mobile device You use, your mobile device

            unique ID, your mobile device's IP address, your mobile operating system, the type of mobile

            Internet browser You use, unique device identifiers, and other diagnostic data.</p>

        <p class="paragraph lh-sm my-3">d. Any data you supply in connection with Sidrabazar Services is

            received and stored by us. You can

            choose not to supply certain information, but you may be unable to use many of our services if you

            do.</p>

        <p class="paragraph lh-sm my-3">e. We gather and keep certain sorts of data regarding your use of our

            services automatically,

            including information about your reviews of the material and services we offer. When your web

            browser or devices accesses our services and other content delivered by or on behalf of Sidrabazar

            on other websites, we, like many other websites, utilises "cookies" and other unique identifiers,

            and we receive certain sorts of data</p>

        <p class="paragraph lh-sm my-3">f. the information you provide when you enter a competition, promotion,

            or advertisement on our

            Platform, as well as testimonials or feedback;</p>

        <p class="paragraph lh-sm my-3">g. data of transactions you do on our Platform and the fulfillment of

            your requests</p>

        <p class="paragraph lh-sm my-3">h. If you indicate that you have given permission for a third party to

            share your information with

            us, we may obtain your email address from them</p>

        <p class="paragraph lh-sm my-3">i. We may obtain information about you from other sources, such as

            updated delivery address

            information from our carriers, which we utilises to fix our records and make your next purchase

            easier to deliver, and any other information we deem necessary to improve your Platform experience

        </p>




        <span class="heading-2"><u>USE OF CUSTOMER DATA</u></span>

        <p class="paragraph lh-sm my-2 mt-3">We use your personal data in the following ways to run, provide,

            develop, and improve the services

            and products we offer to our clients.</p>

        <p class="paragraph my-3">a. To accept and process orders, deliver items and services, process payments,

            and communicate with

            you about orders, products, and promotional offers.</p>

        <p class="paragraph  my-3">b. To learn about your preferences and tailor your experience with our

            services in order to improve

            our services and provide you with a more personalised experience.</p>

        <p class="paragraph  my-3">c. To contact with you about our services and to inform you of any changes to

            our services via

            various channels such as phone, email, chat, or any social media platform.</p>

        <p class="paragraph lh-sm my-3">d. When necessary or reasonable for their implementation, to contact You

            by email, phone calls, SMS,

            or other equivalent forms of electronic communication, such as a mobile application's push

            notifications regarding updates or informative communications related to the functionalities,

            products, or contracted services, including security updates.</p>

        <p class="paragraph my-3">e. To manage Your account as a Service user as a registered user, the Personal

            Data You submit may

            allow You access to certain features of the Service.

        </p>

        <p class="paragraph my-3">f. To preserve the security of our customers and others by preventing and

            detecting abuse.</p>

        <p class="paragraph lh-sm my-3">g. To provide you with location-based services including advertising,

            search results, and other

            personalised information. We only use your personal information to show you interest-based

            advertisements for features, goods, and services that you might find useful. To display

            interest-based advertisements, we do not use personally identifiable information.</p>

        <p class="paragraph lh-sm my-3">h. We may also use Your information for other reasons, such as data

            analysis, identifying usage

            trends, determining the success of promotional efforts, and evaluating and improving our Service,

            goods, services, marketing, and your experience.</p>

        <p class="paragraph lh-sm my-3">i. We gather and use your personal information in specific circumstances

            to comply with the law. For

            example, we collect information from sellers about their location of business and bank account

            information for the purposes of identifying them and verifying their identities.

        </p>




        <span class="heading-2"><u>RETENTION OF YOUR PERSONAL DATA</u></span>

        <p class="paragraph lh-sm my-2 mt-3">We will only keep Your Personal Information for as long as it is

            required for the purposes outlined

            in this Privacy Policy. We will keep and use Your Personal Data to the extent necessary to comply

            with our legal duties (for example, if relevant laws compel us to keep your data), resolve disputes,

            and enforce our legal agreements and policies.</p>

        <p class="paragraph lh-sm my-3">Usage Data will be kept on file for internal analysis. Except where this

            data is used to reinforce

            the security or to improve the functionality of Our Service, or when We are legally compelled to

            retain this data for extended periods of time, Usage Data is normally retained for a shorter amount

            of time.</p>




        <span class="heading-2"><u>DISCLOSURE OF DATA ABOUT CUSTOMERS</u></span>

        <p class="paragraph lh-sm my-2 mt-3">Our firm relies heavily on client information. We only share your

            data with firms who follow policies

            at least as protective as those specified in this Privacy Policy and as indicated below.</p>

        <p class="paragraph lh-sm my-3">a. We may work with affiliates and/or non-affiliated service providers

            to provide you with our

            Services (e.g., logistics businesses used to deliver products to you, marketing companies, payments

            processors to process online transactions, etc.). other businesses may be involved in your

            transactions, and they may store your Information in a digital wallet to help you utilises our

            Services more efficiently.</p>

        <p class="paragraph lh-sm my-3">b. You recognise how critical it is for such businesses to have access

            to relevant data in order to

            fulfil their tasks. We'll make sure that these companies don't use your information for anything

            else. We may also obtain information from these businesses (for example, updated delivery and

            address information), which we may utilise (e.g., to correct our records and deliver your next

            purchase). By using our Platform, you expressly consent to the transfer, storage, use, and

            disclosure of your data among our affiliates and/or non-affiliated service providers, regardless of

            their location. These businesses will be contractually obligated to keep your information private.

        </p>

        <p class="paragraph lh-sm my-3">c. We may also use your information to send you information about goods

            and services that may be of

            interest to you, as well as to improve your Platform experience, service messages, new features,

            updates, special offers, and relevant events. E-mails, push notifications, web notifications, post,

            telephone, in-app messaging, and news feed cards are just some of the ways we may contact you.</p>

        <p class="paragraph lh-sm my-3">d. We may give third parties permission to use your information. For

            example, we may supply data to

            advertisers to assist them in reaching the type of audience they desire while also allowing us to

            meet our obligations to our advertising (e.g., by displaying their advertisements to a target

            audience).</p>

        <p class="paragraph lh-sm my-3">e. In order to participate in certain of our market research activities,

            such as competitions and

            promotions, you may be requested to give extra data. If you win a competition, for example, you may

            be required to give more personal information so that your eligibility can be verified and the

            reward may be awarded to you. We, our co-sponsors, or vendors may gather this information for the

            promotion. It's important to read the data policies of such third parties to determine how they plan

            to utilise whatever information they gather.</p>

        <p class="paragraph lh-sm my-3">f. Customer data would be one of the transferred assets if we or

            substantially all of our assets were

            to be acquired.</p>

        <p class="paragraph lh-sm my-3">g. We reveal account and other data when we believe it is necessary to

            comply with the law and law

            enforcement investigations, as well as to protect our users' or others' rights, property, or safety.

            For numerous reasons, such as fraud protection and credit risk reduction, this includes exchanging

            data with other firms and organisations. We may be forced to share Your Personal Data under certain

            situations, such as where required by law or in response to lawful demands from public authorities

            (e.g., a court or a government agency).</p>

        <p class="paragraph lh-sm my-3">Please be aware that our Platform may contain connections to and from

            the websites of our partner

            networks, advertisers, and affiliates from time to time. If you click on a link to one of these

            websites, please be aware that these websites have their own privacy policies, for which we take no

            responsibility or obligation. Please review these policies before providing any personal information

            or other information to these websites.</p>




        <span class="heading-2">DATA CAN ACCESS BY CUSTOMERS.</span>

        <p class="paragraph lh-sm my-2 mt-3">We intend to gather, store, and process all personal data we

            acquire about customers and other

            individuals. We don't usually share personal information with third parties. However, you may view

            your personal information on the website, including your name, address, payment information,

            transaction history, and notifications and alerts. The details that you can access are listed below.

        </p>

        <p class="paragraph  my-3">a. Personal information, product reviews, and suggestions are all part of the

            profile.</p>

        <p class="paragraph my-3">b. Preferences for communication and recently viewed content</p>

        <p class="paragraph my-3">c. Orders that have been completed or cancelled in the past, as well as the

            status of current orders

        </p>

        <p class="paragraph my-3">d. Name, email, phone number, and address are some of personally identifiable

            information.</p>

        <p class="paragraph my-3">e. Product availability alerts, shipping alerts, and reminders are all

            included in the notification

            settings.</p>

        <p class="paragraph my-3 ">f. Payment card or credit card information can be entered under the payment

            details settings.</p>




        <span class="heading-2">SECURITY POLICY</span>

        <p class="paragraph lh-sm my-2 mt-3">We place a high value on the security of your personal information.

            Sidrabazar does everything

            possible to secure Your Personal Information from unauthorised access, modification, manipulation,

            disclosure, or destruction. For this aim, Sidrabazar employs evaluations of data collection,

            storage, and operating settings, as well as safety steps such as suitable encryption and physical

            security checks to protect against unauthorised access to systems where Sidrabazar keeps Your

            Personal Information. Each Sidrabazar firm shall establish reasonable security policies and

            processes to secure Your Information, as required by relevant laws. We maintain commercially

            reasonable technical, administrative, and physical safeguards to guarantee that your Information is

            treated securely and in compliance with this Privacy Policy, and to protect it from unauthorized

            access, alteration, disclosure, or destruction. We make sure that our systems are safe and secure.

            We use encryption protocol and software to protect the security of your personal data during

            transmission. To avoid network-level assaults, we may, for example, utilise encryption technology to

            secure your Information during transfer to our Platform, as well as external and on-host firewall

            technology. Only those authorised individuals who require access to your information in order to

            execute their duties are permitted to do so. It's critical that you safeguard your password and any

            devices you use to access our Services against unauthorised access. Security features on our devices

            safeguard them against illegal access and data loss. These features are under your control and can

            be customised to meet your specific requirements.</p>

        <p class="paragraph lh-sm my-3">You are solely responsible for the security of your password. It's

            critical that you keep your

            password and device safe from illegal access. When you've finished using a shared device, for

            example, make sure you sign off.</p>

        <p class="paragraph lh-sm my-3">In the collection, storage, and disclosure of personal information, we

            use physical, electronic, and

            procedural measures. Unfortunately, data transfer over the internet is not entirely secure. We will

            try our best to protect your information, but we cannot guarantee the security of any information

            you communicate to our Platform. Any transfer is at your own risk.</p>




        <span class="heading-2">COOKIES POLICY</span>

        <p class="paragraph lh-sm my-2 mt-3">Our website uses cookies and similar technology. A “Cookie” is a

            small piece of information stored by

            a web server on a web browser so it can be later read back from that browser. Cookies are useful for

            enabling the browser to remember information the browser to remember information specific to a given

            user. We place both permanent and temporary cookies in your computer’s hard drive. The cookies do

            not contain any of your personally identifiable information.</p>

        <p class="paragraph lh-sm my-3">These cookies allow us to distinguish you from other users of our

            website. You can read more about

            cookies and how to recognise them at Sidrabazar.com Cookies perform many functions, such as allowing

            you to navigate between pages efficiently, remembering your preferences, and generally improving the

            user experience. They can also help to ensure that advertising that you see online is more relevant

            to you and your interests. Your use of our website indicates your consent to the cookies described

            in this policy</p>

        <p class="paragraph lh-sm my-3">When you visit our website, we may use cookies and other technologies to

            automatically collect the

            following information:</p>

        <p class="paragraph lh-sm my-3">Technical information, including the Internet Protocol (IP) address used

            to connect your computer to

            the internet, your login information, browser type and version, device identifier, location and time

            zone setting, browser plug-in types and versions, operating system and platform, page response

            times, and download errors;</p>

        <p class="paragraph lh-sm my-3">Information about your visit, including the full Uniform Resource

            Locators (URL) clickstreams to, on

            and from our websites;</p>

        <p class="paragraph lh-sm my-3">Length of visits to certain pages, page interaction information (such as

            scrolling, clicks, and

            mouseovers) and methods used to browse away from the page.</p>

        <p class="paragraph lh-sm my-3">Your personal information is stored by us and/or our Service Providers,

            strictly to the extent

            necessary for the performance of our obligations and strictly for the time necessary to achieve the

            purposes for which the information is collected, in accordance with applicable data protection laws

            in UAE. When we no longer need to use your information, we will remove it from our systems and

            records and/or take steps to properly anonymise it so that you can no longer be identified from it

            (unless we need to keep your information to comply with legal or regulatory obligations to which we

            are subject).</p>




        <span class="heading-2">ADVERTISEMENT POLICY</span>

        <p class="paragraph lh-sm my-2 mt-3">Certain content, products and services available via our Service

            may include materials from

            third-parties. Third-party links on this site may direct you to third-party websites that are not

            affiliated with us. We are not responsible for examining or evaluating the content or accuracy and

            we do not warrant and will not have any liability or responsibility for any third-party materials or

            websites, or for any other materials, products, or services of third-parties.</p>

        <p class="paragraph lh-sm my-3">We are not liable for any harm or damages related to the purchase or use

            of goods, services,

            resources, content, or any other transactions made in connection with any third-party websites.

            Please review carefully the third-party's policies and practices and make sure you understand them

            before you engage in any transaction. Complaints, claims, concerns, or questions regarding

            third-party products should be directed to the third-party.</p>

        <p class="paragraph lh-sm my-3">We use third-party advertising companies to serve ads when you visit our

            website. These companies may

            use information (not including your name, address, email address, or telephone number) about your

            visits to this and other websites in order to provide advertisement about goods and services of

            interest to you.</p>




        <h4 class="heading-1 mb-2">Security Policy</h4>

        <span class="date">Last updated: 2022-08-04T09:23:18</span>

        <p class="paragraph lh-sm my-3">We place a high value on the security of your personal information. SOUQ

            RAWABI LLC does everything

            possible to secure Your Personal Information from unauthorised access, modification, manipulation,

            disclosure, or destruction. For this aim, SOUQ RAWABI LLC employs evaluations of data collection,

            storage, and operating settings, as well as safety steps such as suitable encryption and physical

            security checks to protect against unauthorised access to systems where SOUQ RAWABI LLC keeps Your

            Personal Information. Each SOUQ RAWABI LLC firm shall establish reasonable security policies and

            processes to secure Your Information, as required by relevant laws. We maintain commercially

            reasonable technical, administrative, and physical safeguards to guarantee that your Information is

            treated securely and in compliance with this Privacy Policy, and to protect it from unauthorized

            access, alteration, disclosure, or destruction. We make sure that our systems are safe and secure.

            We use encryption protocol and software to protect the security of your personal data during

            transmission. To avoid network-level assaults, we may, for example, utilise encryption technology to

            secure your Information during transfer to our Platform, as well as external and on-host firewall

            technology. Only those authorised individuals who require access to your information in order to

            execute their duties are permitted to do so. It's critical that you safeguard your password and any

            devices you use to access our Services against unauthorised access. Security features on our devices

            safeguard them against illegal access and data loss. These features are under your control and can

            be customised to meet your specific requirements.</p>

        <p class="paragraph lh-sm my-3">You are solely responsible for the security of your password. It's

            critical that you keep your

            password and device safe from illegal access. When you've finished using a shared device, for

            example, make sure you sign off.</p>

        <p class="paragraph lh-sm my-3">In the collection, storage, and disclosure of personal information, we

            use physical, electronic, and

            procedural measures. Unfortunately, data transfer over the internet is not entirely secure. We will

            try our best to protect your information, but we cannot guarantee the security of any information

            you communicate to our Platform. Any transfer is at your own risk.</p>




        <h4 class="heading-1 mb-2">General Policy</h4>

        <span class="date">Last updated: Aug 4, 2022</span>

        <p class="paragraph lh-sm my-3">If any clause of this agreement is found to be defective, void, or

            unenforceable for any reason, the

            remaining parts of this user agreement will remain valid and enforceable. This user agreement (as

            updated from time to time in accordance with its terms) contains the complete understanding and

            agreement between you and Sidrabazar.com' with respect to the subject matter hereof.</p>

        <p class="paragraph lh-sm my-3">Any term of these Terms of Use cannot be enforced by anybody who is not

            a party to them. No one who

            is not a party to this agreement has the authority to enforce any of the terms of this agreement. If

            any of these terms of service are translated into a language other than English, whether on the site

            or elsewhere, the English content will take precedence.</p>

        <p class="paragraph lh-sm my-3">Unless we specifically provide otherwise in writing, any waiver by us of

            any of the provisions of

            these Terms of Use will not constitute a waiver of any other provision (whether similar or not), nor

            will any such waiver represent a continuous waiver of that particular provision. All terms that

            expressly or by their nature survive suspension or termination of your membership in the Site will

            also survive suspension or termination of your membership in the Site.</p>

        <p class="paragraph lh-sm my-3">Neither party will be liable for any loss or damage, or any delay or

            failure in performance, caused

            by events beyond its control, whether or not such events could have been reasonably anticipated

            (including acts of God, legislative, judicial or regulatory acts of any provincial or the federal

            government, court or regulatory authority, acts of any of our subcontractors or any third-party

            providers of goods or Services to us, labour disruptions, blackouts, embargoes).</p>




        <h4 class="heading-1 mb-2">Data Policy</h4>

        <span class="date">Last updated: Aug 4, 2022</span>

        <p class="paragraph lh-sm my-3">We see it as part of our social responsibility to preserve data privacy

            rights. We need to collect

            and process data as part of our operations. Any offline or online data that identifies a person,

            such as names, addresses, usernames and passwords, digital footprints, photos, social security

            numbers, financial data, and so on, is included in this data. We collect this data in an open and

            transparent manner, with the full consent and knowledge of all parties involved. Following are the

            regulations that apply after we get this information.</p>

        <span class="heading-2 ">DATA COLLECTED FROM CUSTOMERS</span>

        <p class="paragraph lh-sm my-2 mt-3">We may collect and handle the following information about you:</p>

        <p class="paragraph lh-sm my-3">a) Data you provide by filling out forms on our Platform, such as data

            provided when registering to

            use our Platform and other co-registrations (e.g., online media logins), subscribing to our

            Services, uploading material, or referencing further administrations;</p>

        <p class="paragraph lh-sm my-3">b) Usage Data may include information such as Your Device's Internet

            Protocol address (IP Address),

            browser type, browser version, the pages of our Service that You visit, the time and date of your

            visit, the time spent on those pages, unique device identifiers, and other diagnostic data, as well

            as data about your overall internet usage (for example, by utilising technology that stores data on

            or accesses your device, such as Cookies, Tracking pixels, web beacons, and other diagnostic data),

            as well as data about your overall</p>

        <p class="paragraph lh-sm my-3">c) We may collect certain information automatically when You access the

            Service via or through a

            mobile device, including, but not limited to, the type of mobile device You use, your mobile device

            unique ID, your mobile device's IP address, your mobile operating system, the type of mobile

            Internet browser You use, unique device identifiers, and other diagnostic data.</p>

        <p class="paragraph lh-sm my-3">d) Any data you supply in connection with Sidrabazar Services is

            received and stored by us. You can

            choose not to supply certain information, but you may be unable to use many of our services if you

            do.</p>

        <p class="paragraph lh-sm my-3">e) We gather and keep certain sorts of data regarding your use of our

            services automatically,

            including information about your reviews of the material and services we offer. When your web

            browser or devices accesses our services and other content delivered by or on behalf of Sidrabazar

            on other websites, we, like many other websites, utilise "cookies" and other unique identifiers, and

            we receive certain sorts of data.</p>

        <p class="paragraph lh-sm my-3">f) the information you provide when you enter a competition, promotion,

            or advertisement on our

            Platform, as well as testimonials or feedback;</p>

        <p class="paragraph lh-sm my-3">g) data of transactions you do on our Platform and the fulfilment of

            your requests.</p>

        <p class="paragraph lh-sm my-3">h) If you indicate that you have given permission for a third party to

            share your information with

            us, we may obtain your email address from them.</p>

        <p class="paragraph lh-sm my-3">i) We may obtain information about you from other sources, such as

            updated delivery address

            information from our carriers, which we utilise to fix our records and make your next purchase

            easier to deliver, and any other information we deem necessary to improve your Platform experience.

        </p>




        <span class="heading-2">USE OF CUSTOMER DATA</span>

        <p class="paragraph lh-sm my-2 mt-3">We use your personal data in the following ways to run, provide,

            develop, and improve the services

            and products we offer to our clients.</p>

        <p class="paragraph lh-sm my-3">a) To accept and process orders, deliver items and services, process

            payments, and communicate with

            you about orders, products, and promotional offers.</p>

        <p class="paragraph lh-sm my-3">b) To learn about your preferences and tailor your experience with our

            services in order to improve

            our services and provide you with a more personalised experience.</p>

        <p class="paragraph lh-sm my-3">c) To contact with you about our services and to inform you of any

            changes to our services via

            various channels such as phone, email, chat, or any social media platform.</p>

        <p class="paragraph lh-sm my-3">d) When necessary or reasonable for their implementation, to contact You

            by email, phone calls, SMS,

            or other equivalent forms of electronic communication, such as a mobile application's push

            notifications regarding updates or informative communications related to the functionalities,

            products, or contracted services, including security updates.</p>

        <p class="paragraph lh-sm my-3">e) To manage Your account as a Service user as a registered user, the

            Personal Data You submit may

            allow You access to certain features of the Service.</p>

        <p class="paragraph lh-sm my-3">f) To preserve the security of our customers and others by preventing

            and detecting abuse.</p>

        <p class="paragraph lh-sm my-3">g) To provide you with location-based services including advertising,

            search results, and other

            personalised information. We only use your personal information to show you interest-based

            advertisements for features, goods, and services that you might find useful. To display

            interest-based advertisements, we do not use personally identifiable information.</p>

        <p class="paragraph lh-sm my-3">h) We may also use Your information for other reasons, such as data

            analysis, identifying usage

            trends, determining the success of promotional efforts, and evaluating and improving our Service,

            goods, services, marketing, and your experience.</p>

        <p class="paragraph lh-sm my-3">i) We gather and use your personal information in specific circumstances

            to comply with the law. For

            example, we collect information from sellers about their location of business and bank account

            information for the purposes of identifying them and verifying their identities.</p>




        <span class="heading-2">RETENTION OF YOUR PERSONAL DATA</span>

        <p class="paragraph lh-sm my-2 mt-3">We will only keep Your Personal Information for as long as it is

            required for the purposes outlined

            in this Privacy Policy. We will keep and use Your Personal Data to the extent necessary to comply

            with our legal duties (for example, if relevant laws compel us to keep your data), resolve disputes,

            and enforce our legal agreements and policies. Usage Data will be kept on file for internal

            analysis. Except where this data is used to reinforce the security or to improve the functionality

            of Our Service, or when We are legally compelled to retain this data for extended periods of time,

            Usage Data is normally retained for a shorter amount of time. DISCLOSURE OF DATA ABOUT CUSTOMERS Our

            firm relies heavily on client information. We only share your data with firms who follow policies at

            least as protective as those specified in this Privacy Policy and as indicated below.</p>

        <p class="paragraph lh-sm my-3">a) We may work with affiliates and/or non-affiliated service providers

            to provide you with our

            Services (e.g., logistics businesses used to deliver products to you, marketing companies, payments

            processers to process online transactions, etc.). other businesses may be involved in your

            transactions, and they may store your Information in a digital wallet to help you utilise our

            Services more efficiently.</p>

        <p class="paragraph lh-sm my-3">b) You recognise how critical it is for such businesses to have access

            to relevant data in order to

            fulfil their tasks. We'll make sure that these companies don't use your information for anything

            else. We may also obtain information from these businesses (for example, updated delivery and

            address information), which we may utilise (e.g., to correct our records and deliver your next

            purchase). By using our Platform, you expressly consent to the transfer, storage, use, and

            disclosure of your data among our affiliates and/or non-affiliated service providers, regardless of

            their location. These businesses will be contractually obligated to keep your information private.

        </p>

        <p class="paragraph lh-sm my-3">c) We may also use your information to send you information about goods

            and services that may be of

            interest to you, as well as to improve your Platform experience, service messages, new features,

            updates, special offers, and relevant events. E-mails, push notifications, web notifications, post,

            telephone, in-app messaging, and news feed cards are just some of the ways we may contact you.</p>

        <p class="paragraph lh-sm my-3">d) We may give third parties permission to use your information. For

            example, we may supply data to

            advertisers to assist them in reaching the type of audience they desire while also allowing us to

            meet our obligations to our advertising (e.g., by displaying their advertisements to a target

            audience.</p>

        <p class="paragraph lh-sm my-3">e) In order to participate in certain of our market research activities,

            such as competitions and

            promotions, you may be requested to give extra data. If you win a competition, for example, you may

            be required to give more personal information so that your eligibility can be verified and the

            reward may be awarded to you. We, our co-sponsors, or vendors may gather this information for the

            promotion. It's important to read the data policies of such third parties to determine how they plan

            to utilise whatever information they gather.</p>

        <p class="paragraph lh-sm my-3">f) Customer data would be one of the transferred assets if we or

            substantially all of our assets were

            to be acquired.</p>

        <p class="paragraph lh-sm my-3">g) We reveal account and other data when we believe it is necessary to

            comply with the law and law

            enforcement investigations, as well as to protect our users' or others' rights, property, or safety.

            For numerous reasons, such as fraud protection and credit risk reduction, this includes exchanging

            data with other firms and organisations. We may be forced to share Your Personal Data under certain

            situations, such as where required by law or in response to lawful demands from public authorities

            (e.g., a court or a government agency).</p>

        <p class="paragraph lh-sm my-3">Please be aware that our Platform may contain connections to and from

            the websites of our partner

            networks, advertisers, and affiliates from time to time. If you click on a link to one of these

            websites, please be aware that these websites have their own privacy policies, for which we take no

            responsibility or obligation. Please review these policies before providing any personal information

            or other information to these websites.</p>




        <span class="heading-2">DATA CAN ACCESS BY CUSTOMERS.</span>

        <p class="paragraph lh-sm my-2 mt-3">We intend to gather, store, and process all personal data we

            acquire about customers and other

            individuals. We don't usually share personal information with third parties. However, you may view

            your personal information on the website, including your name, address, payment information,

            transaction history, and notifications and alerts. The details that you can access are listed below.

        </p>

        <p class="paragraph lh-sm my-3">a) Personal information, product reviews, and suggestions are all part

            of the profile.</p>

        <p class="paragraph lh-sm my-3">b) Preferences for communication and recently viewed content</p>

        <p class="paragraph lh-sm my-3">c) Orders that have been completed or cancelled in the past, as well as

            the status of current orders

        </p>

        <p class="paragraph lh-sm my-3">d) Name, email, phone number, and address are some of personally

            identifiable information.</p>

        <p class="paragraph lh-sm my-3">e) Product availability alerts, shipping alerts, and reminders are all

            included in the notification

            settings.</p>

        <p class="paragraph lh-sm my-3">f) Payment card or credit card information can be entered under the

            payment details settings.</p>




        <h4 class="heading-1 mb-2">Cookies Policy</h4>

        <span class="date">Last updated: 2022-08-04T09:23:00</span>

        <p class="paragraph lh-sm my-3">Our website uses cookies and similar technology. A “Cookie” is a small

            piece of information stored by

            a web server on a web browser so it can be later read back from that browser. Cookies are useful for

            enabling the browser to remember information the browser to remember information specific to a given

            user. We place both permanent and temporary cookies in your computer’s hard drive. The cookies do

            not contain any of your personally identifiable information. These cookies allow us to distinguish

            you from other users of our website. You can read more about cookies and how to recognise them at

            Sidrabazar.com Cookies perform many functions, such as allowing you to navigate between pages

            efficiently, remembering your preferences, and generally improving the user experience. They can

            also help to ensure that advertising that you see online is more relevant to you and your interests.

            Your use of our website indicates your consent to the cookies described in this policy.</p>

        <p class="paragraph lh-sm my-3">When you visit our website, we may use cookies and other technologies to

            automatically collect the

            following information:</p>

        <p class="paragraph lh-sm my-3">• Technical information, including the Internet Protocol (IP) address

            used to connect your computer

            to the internet, your login information, browser type and version, device identifier, location and

            time zone setting, browser plug-in types and versions, operating system and platform, page response

            times, and download errors;</p>

        <p class="paragraph lh-sm my-3">• Information about your visit, including the full Uniform Resource

            Locators (URL) clickstreams to,

            on and from our websites;</p>

        <p class="paragraph lh-sm my-3">• Length of visits to certain pages, page interaction information (such

            as scrolling, clicks, and

            mouseovers) and methods used to browse away from the page.</p>

        <p class="paragraph lh-sm my-3">Your personal information is stored by us and/or our Service Providers,

            strictly to the extent

            necessary for the performance of our obligations and strictly for the time necessary to achieve the

            purposes for which the information is collected, in accordance with applicable data protection laws

            in UAE. When we no longer need to use your information, we will remove it from our systems and

            records and/or take steps to properly anonymise it so that you can no longer be identified from it

            (unless we need to keep your information to comply with legal or regulatory obligations to which we

            are subject).</p>




    </div>




</div>

<div class="footer d-none d-lg-block d-md-block d-sm-block">

    <div class="col-12 row ">

        <div class="col-lg-4 col-12"></div>

        <div class="col-lg-4 col-12  d-flex align-items-center justify-content-center ">

            <p class="copyright  my-3"> © 2023 Sidrabazar.com All Rights Reserved</p>

            <span class="privacytag ms-2 cursor" [routerLink]="['/privacyPolicy']">privacy & policy</span>

            <span class="privacytag ms-2 cursor" [routerLink]="['/terms-use']">Terms-use</span>

        </div>

        <div class="col-lg-4 col-12"></div>




    </div>

</div>

<div class="footer d-block d-sm-none">

    <div class="col-12 row ">

        <div class=" col-12 d-flex align-items-center justify-content-center">

            <p class="copyright  my-2 ms-3"> © 2023 Sidrabazar.com All Rights Reserved</p>

        </div>

        <div class=" col-12  d-flex align-items-center justify-content-center ">

            <span class="privacytag ms-2 cursor" [routerLink]="['/privacyPolicy']">privacy & policy</span>

            <span class="privacytag ms-2 cursor" [routerLink]="['/terms-use']">Terms-use</span>

        </div>

    </div>

</div>

 