import { Component, OnInit, HostListener } from '@angular/core';
import { CategoryService } from 'src/app/services/category.service';
import { LocationStrategy } from '@angular/common';
// import * as isoCountries from 'i18n-iso-countries';
import { HttpClient } from '@angular/common/http';
import { ProductService } from 'src/app/services/product.service';
import { MessengerService } from 'src/app/services/messenger.service';
// import { Banner, BannerCategory } from 'src/app/models/banner';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { environment } from 'src/environments/environment';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { NgxQrcodeElementTypes, NgxQrcodeErrorCorrectionLevels } from '@techiediaries/ngx-qrcode';

@Component({
  selector: 'app-test',
  templateUrl: './test.component.html',
  styleUrls: ['./test.component.scss']
})
export class TestComponent implements OnInit {
  image = '';
  name = '';
  hashtags = '';
  url = '';
  fbid = '';
  elementType = NgxQrcodeElementTypes.URL;
  correctionLevel = NgxQrcodeErrorCorrectionLevels.HIGH;
  value = '50';
  buttons: { label: string, color: string }[] = [
    { label: 'Button 1', color: 'red' },
    { label: 'Button 2', color: 'blue' },
    { label: 'Button 3', color: 'green' },
  ];
  items: any = [
    { "num": "1" }, { "num": "2" }, { "num": "3" }, { "num": "4" },
    { "num": "1" }, { "num": "2" }, { "num": "9" }, { "num": "24" },
    { "num": "13" }, { "num": "25" }, { "num": "30" }, { "num": "47" }, { "num": "01" }, { "num": "28" }, { "num": "34" }, { "num": "48" },
  ];
  p=5
 
  data: any[] = [
    { "name": 'Item 1' },
    { "name": 'Item 2' },
    { "name": 'Item 3' },
    { "name": 'Item 4' },
    { "name": 'Item 5' },
    { "name": 'Item 6' },
    { "name": 'Item 7' },
    { "name": 'Item 8' },
    { "name": 'Item 1' },
    { "name": 'Item 2' },
    { "name": 'Item 3' },
    { "name": 'Item 4' },
    { "name": 'Item 5' },
    { "name": 'Item 6' },
    { "name": 'Item 7' },
    { "name": 'Item 8' },
    { "name": 'Item 1' },
    { "name": 'Item 2' },
    { "name": 'Item 3' },
    { "name": 'Item 4' },
    { "name": 'Item 5' },
    { "name": 'Item 6' },
    { "name": 'Item 7' },
    { "name": 'Item 8' },
    // Add more items as needed
  ];
  currentPage: number=1;
  totalPages: number=20;
  // pages:any
  // numbers: number[] = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20];
  // currentIndex: number = 0;
  // numberGroup: number[] = [];

  profileForm: FormGroup;
  imageChangedEvent: any = '';
  croppedImage: any = '';
  isCropperOpen = true;
  constructor(private router: Router,
    private fb: FormBuilder,
    private sanitizer: DomSanitizer,
    private _productService: ProductService,
    private _messageService: MessengerService,
    private auth: AuthService) {
    this.profileForm = this.fb.group({
      profilePicture: [''],
    });
  }
  ngOnInit(): void {
  }

  get visiblePages(): number[] {
    const start = Math.max(1, this.currentPage - 2);
    const end = Math.min(this.totalPages, start + 4);
    const pages = [];

    for (let i = start; i <= end; i++) {
      pages.push(i);
    }

    return pages;
  }



  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
}
cropperReady() {
    // cropper ready
}
loadImageFailed() {
    // show message
}

closeCropper() {
  this.isCropperOpen = false;
}

}

















