import { Component, HostListener, OnInit } from '@angular/core';
import { ProductService } from 'src/app/services/product.service';
import { MessengerService } from 'src/app/services/messenger.service';
import { environment } from 'src/environments/environment';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { Location } from '@angular/common';


let headers:any
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  specialGroups = environment.specialGroups
  bannerList:any = []
  languageShow:any
  api_user=environment.api_user
  shouldReload: boolean = true;
  headermenue:boolean=true
  constructor(private _productService: ProductService, private _messageService:MessengerService,private http:HttpClient,
    private router:Router,private location: Location) {
    // this._messageService.getSegments().subscribe((data:any)=>{
    //   this.specialGroups = data
    // })
   }

  ngOnInit(): void {
    let user:any = localStorage.getItem('marketplaceUser')
    let userDetails = JSON.parse(user) 
    let tokens=userDetails?.token
    headers=new HttpHeaders({
      Authorization: `token ${tokens}`
    })
    this.http.get(this.api_user+"/user-account_check-auth-token",{headers}).toPromise().then((data:any)=>{
  
    })
    .catch((error) => {
      localStorage.clear()
      // location.reload();
      // this.router.navigate(['/']);
      console.log("africa:", error);
    });
    // let special = localStorage.getItem('specialGroup')
    // if(special !=null){
    //   this.specialGroups = special
    // }else{
    //   this.specialGroups = environment.specialGroups
    // }
    // this._productService.getHomeBanner().subscribe((data:any)=>{
    //   this.bannerList = data?.data
    //   this.bannerList.products = this.bannerList?.products?.sort((a:any, b:any) => parseFloat(a.Priorty) - parseFloat(b.Priorty))
    // })
    let language:any = localStorage.getItem('languageName')
    this.languageShow = JSON.parse(language)

    this._messageService.getheaderhide().subscribe(()=>{
      const isMobileView = window.innerWidth <= 575;
    if(isMobileView){
      this.headermenue=false
    }
     
    })

    this._messageService.getshowheader().subscribe(()=>{
      this.headermenue=true
    })
  }

  scrollUp(){
    window.scrollTo(0,0)
  }
  // specialGroup(specialGroup:any,name:any){
  //   this._messageService.sendSegments(specialGroup)
  //   this.specialGroups = specialGroup
  //   localStorage.setItem('specialGroup',this.specialGroups)
  // } 


  showButton = false;
  buttonStyles = {};

  @HostListener('window:scroll', ['$event'])
  onScroll(event: Event): void {
    const scrollY = window.scrollY;

    if (scrollY < 400) {
      // Hide the button when at the top
      this.showButton = false;
    } else {
      // Show the button when scrolling starts
      this.showButton = true;
    }
  }

  
}
