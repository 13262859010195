import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
  sendEmail() {
    const organizationEmail = 'care@sidrabazar.com';
    const emailSubject = 'Subject';
    const mailtoLink = `mailto:${organizationEmail}?subject=${encodeURIComponent(emailSubject)}`;
    
    window.location.href = mailtoLink;
  }
}
