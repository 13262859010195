import { Component, OnInit } from '@angular/core';
import { ProductService } from 'src/app/services/product.service';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CartService } from 'src/app/services/cart.service';
import { MessengerService } from 'src/app/services/messenger.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  footerCreditLink = environment.footer_credit_link
  footerCreditText = environment.footer_credit_text
  social_media = environment.social_media
  playstore = environment.apps.playstore
  appstore = environment.apps.appstore
  isLogin=false
  groceryArray:any=[]
  bulkArray:any=[]
  smartArray:any=[]
  languageShow:any
  segmentArray:any=[]
  userData:any
  constructor(private _productService:ProductService,
              private router: Router,
              public translate: TranslateService,
              private cart:CartService,
              private msg:MessengerService) { 
                let language:any = localStorage.getItem('languageName')
                this.languageShow = JSON.parse(language) 
                translate.addLangs(['en', 'arb']); 
                if(this.languageShow=="rtl"){
                  translate.setDefaultLang('arb'); 
                  const browserLang = translate.getBrowserLang();
                  translate.use('arb');
                }else{
                  translate.setDefaultLang('en'); 
                  const browserLang = translate.getBrowserLang();
                  translate.use('en');
                }
                this.cart.getSegmant().subscribe((d:any)=>{
                  this.segmentArray=d.data?.results
                }) 
              }


  ngOnInit(): void {
    let user:any = localStorage.getItem("marketplaceUser")
    this.userData = JSON.parse(user)
    if(this.userData !=null){
      this.isLogin=true
    }
    let specialGroup="groceries"
    let smart ="smart"
    let bulk ="bulk"
    
    // this._productService.getHomeMainSlotOne(specialGroup).subscribe((data:any)=>{
    //   this.groceryArray = data?.data?.products
    // })
    // this._productService.getHomeMainSlotOne(smart).subscribe((data:any)=>{
    //   this.smartArray = data?.data?.products
      
    // })
    // this._productService.getHomeMainSlotOne(bulk).subscribe((data:any)=>{
    //   this.bulkArray = data?.data?.products
    // })
    let language:any = localStorage.getItem('languageName')
    this.languageShow = JSON.parse(language)
  }
  // scrollUp(){
  //   window.scrollTo(0, 0);
  // }
  routerLink(name:any,code:any,group:any,id:any){
    this.router.navigate(['/division/' + name+'_$_'+ code+'_$_'+group+'_$_'+id+'_$_'+'category'])

  }

  sendEmail() {
    const organizationEmail = 'care@sidrabazar.com';
    const emailSubject = 'Subject';
    const mailtoLink = `mailto:${organizationEmail}?subject=${encodeURIComponent(emailSubject)}`;
    
    window.location.href = mailtoLink;
  }

  segmentClick(name:any){
    let specialGroups = name
    // this.segmentName=d 
    localStorage.setItem('segmentname',JSON.stringify(specialGroups))
    let groupname:any = localStorage.getItem('segmentname')
    // this.specialGroups = JSON.parse(groupname)
    this.msg.sendSegments(name)
    this.router.navigate(['/'])
    window.scrollTo(0,0)

  }

  sellwithus(){
    window.open('https://seller.sidrabazar.com/', '_blank');
  }

  career(){
    window.open('https://careers.sidrabazar.com/', '_blank');
  }

  linkClick(name:any){
    if(name=="insta"){
      window.open('https://www.instagram.com/sidrabazar', '_blank');
    }
    if(name=="twitter"){
      window.open('https://twitter.com/SidraBazar', '_blank');
    }
    if(name=="whatsapp"){
      window.open('https://api.whatsapp.com/send?phone=971504459003', '_blank');
    }
    if(name=="facebook"){
      window.open('https://www.facebook.com/profile.php?id=100090996295975', '_blank');
    }
  }

}
