<!-- <div class="policy-header">
    <div class="policy-header__title text-center">
        {{genaralPolicy?.title}}
        <div class="w-100 fz-13 text-center mt-3">
            Last updated: {{genaralPolicy?.last_updated | date}}
        </div>
    </div>
</div>

<div class="container-fluid">
    <div class="container py-5" [innerHtml]="genaralPolicy?.description">
    </div>
</div> -->
<div class="py-1 px-3 box-1  shadow" style="position:fixed;width: 100%;top: 0;">

    <div class=" d-flex justify-content-between ">

        <div class="">

            <a [routerLink]="['/']">

                <img src="../../../assets/assets/Sidra Logo 2 hori-15 1.svg" width="210" alt="">

            </a>

        </div>

        <div class=" d-flex align-items-center justify-content-end me-sm-3 me-0 ms-3">

            <div class="iconborder d-flex justify-content-center align-items-center  me-2">
                <a class="atag " href="https://twitter.com/SidraBazar">
                    <span class="fz-15" uk-icon="twitter"></span>
                </a>
            </div>

            <div class="iconborder d-flex justify-content-center align-items-center me-2">
                <a class="atag"href="https://www.facebook.com/profile.php?id=100090996295975">
                    <span class="fz-15" uk-icon="facebook"></span>
                </a>
            </div>

            <div class="iconborder d-flex justify-content-center align-items-center  me-2">
                <a class="atag" href="https://api.whatsapp.com/send?phone=971504459003">
                    <span class="fz-15" uk-icon="whatsapp"></span>
                </a>
            </div>

            <div class="iconborder d-flex justify-content-center align-items-center  ">
                <a class="atag" href="https://www.instagram.com/sidrabazar/">
                    <span   class="fz-15" uk-icon="instagram"></span>
                </a>
            </div>
        </div>

    </div>

</div>
<div class="main-container px-3 py-sm-5 py-3">

    <div class="px-sm-5 px-2 pt-4 pb-5 box mt-5">

        <span class="heading"><u>DISCLOSURE OF DATA ABOUT CUSTOMERS</u></span>

        <p class="paragraph lh-sm my-2 mt-3">Our firm relies heavily on client information. We only share your

            data with firms who follow policies

            at least as protective as those specified in this Privacy Policy and as indicated below.</p>

        <p class="paragraph lh-sm my-3">a. We may work with affiliates and/or non-affiliated service providers

            to provide you with our

            Services (e.g., logistics businesses used to deliver products to you, marketing companies, payments

            processors to process online transactions, etc.). other businesses may be involved in your

            transactions, and they may store your Information in a digital wallet to help you utilises our

            Services more efficiently.</p>

        <p class="paragraph lh-sm my-3">b. You recognise how critical it is for such businesses to have access

            to relevant data in order to

            fulfil their tasks. We'll make sure that these companies don't use your information for anything

            else. We may also obtain information from these businesses (for example, updated delivery and

            address information), which we may utilise (e.g., to correct our records and deliver your next

            purchase). By using our Platform, you expressly consent to the transfer, storage, use, and

            disclosure of your data among our affiliates and/or non-affiliated service providers, regardless of

            their location. These businesses will be contractually obligated to keep your information private.

        </p>

        <p class="paragraph lh-sm my-3">c. We may also use your information to send you information about goods

            and services that may be of

            interest to you, as well as to improve your Platform experience, service messages, new features,

            updates, special offers, and relevant events. E-mails, push notifications, web notifications, post,

            telephone, in-app messaging, and news feed cards are just some of the ways we may contact you.</p>

        <p class="paragraph lh-sm my-3">d. We may give third parties permission to use your information. For

            example, we may supply data to

            advertisers to assist them in reaching the type of audience they desire while also allowing us to

            meet our obligations to our advertising (e.g., by displaying their advertisements to a target

            audience).</p>

        <p class="paragraph lh-sm my-3">e. In order to participate in certain of our market research activities,

            such as competitions and

            promotions, you may be requested to give extra data. If you win a competition, for example, you may

            be required to give more personal information so that your eligibility can be verified and the

            reward may be awarded to you. We, our co-sponsors, or vendors may gather this information for the

            promotion. It's important to read the data policies of such third parties to determine how they plan

            to utilise whatever information they gather.</p>

        <p class="paragraph lh-sm my-3">f. Customer data would be one of the transferred assets if we or

            substantially all of our assets were

            to be acquired.</p>

        <p class="paragraph lh-sm my-3">g. We reveal account and other data when we believe it is necessary to

            comply with the law and law

            enforcement investigations, as well as to protect our users' or others' rights, property, or safety.

            For numerous reasons, such as fraud protection and credit risk reduction, this includes exchanging

            data with other firms and organisations. We may be forced to share Your Personal Data under certain

            situations, such as where required by law or in response to lawful demands from public authorities

            (e.g., a court or a government agency).</p>

        <p class="paragraph lh-sm my-3">Please be aware that our Platform may contain connections to and from

            the websites of our partner

            networks, advertisers, and affiliates from time to time. If you click on a link to one of these

            websites, please be aware that these websites have their own privacy policies, for which we take no

            responsibility or obligation. Please review these policies before providing any personal information

            or other information to these websites.</p>




        <span class="heading"><u>DATA CAN ACCESS BY CUSTOMERS</u></span>

        <p class="paragraph lh-sm my-2 mt-3">We intend to gather, store, and process all personal data we

            acquire about customers and other

            individuals. We don't usually share personal information with third parties. However, you may view

            your personal information on the website, including your name, address, payment information,

            transaction history, and notifications and alerts. The details that you can access are listed below.

        </p>

        <p class="paragraph  my-3">a. Personal information, product reviews, and suggestions are all part of the

            profile.</p>

        <p class="paragraph my-3">b. Preferences for communication and recently viewed content</p>

        <p class="paragraph my-3">c. Orders that have been completed or cancelled in the past, as well as the

            status of current orders

        </p>

        <p class="paragraph my-3">d. Name, email, phone number, and address are some of personally identifiable

            information.</p>

        <p class="paragraph my-3">e. Product availability alerts, shipping alerts, and reminders are all

            included in the notification

            settings.</p>

        <p class="paragraph my-3 ">f. Payment card or credit card information can be entered under the payment

            details settings.</p>

    </div>

<div class="footer d-none d-lg-block d-md-block d-sm-block">

    <div class="col-12 row ">

        <div class="col-lg-4 col-12"></div>

        <div class="col-lg-4 col-12  d-flex align-items-center justify-content-center ">

            <p class="copyright  my-3"> © 2023 Sidrabazar.com All Rights Reserved</p>

            <span class="privacytag ms-2 cursor" [routerLink]="['/privacyPolicy']">privacy & policy</span>

            <span class="privacytag ms-2 cursor" [routerLink]="['/terms-use']">Terms-use</span>

        </div>

        <div class="col-lg-4 col-12"></div>




    </div>

</div>

<div class="footer d-block d-sm-none">

    <div class="col-12 row ">

        <div class=" col-12 d-flex align-items-center justify-content-center">

            <p class="copyright  my-2 ms-3"> © 2023 Sidrabazar.com All Rights Reserved</p>

        </div>

        <div class=" col-12  d-flex align-items-center justify-content-center ">

            <span class="privacytag ms-2 cursor" [routerLink]="['/privacyPolicy']">privacy & policy</span>

            <span class="privacytag ms-2 cursor" [routerLink]="['/terms-use']">Terms-use</span>

        </div>

    </div>

</div>

 