import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer3',
  templateUrl: './footer3.component.html',
  styleUrls: ['./footer3.component.scss']
})
export class Footer3Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
  sendEmail() {
    const organizationEmail = 'care@sidrabazar.com';
    const emailSubject = 'Subject';
    const mailtoLink = `mailto:${organizationEmail}?subject=${encodeURIComponent(emailSubject)}`;
    
    window.location.href = mailtoLink;
  }

  linkClick(name:any){
    if(name=="insta"){
      window.open('https://www.instagram.com/sidrabazar', '_blank');
    }
    if(name=="twitter"){
      window.open('https://twitter.com/SidraBazar', '_blank');
    }
    if(name=="whatsapp"){
      window.open('https://api.whatsapp.com/send?phone=971504459003', '_blank');
    }
    if(name=="facebook"){
      window.open('https://www.facebook.com/profile.php?id=100090996295975', '_blank');
    }
  }
}
