import { Component, OnInit } from '@angular/core';
import { CartService } from 'src/app/services/cart.service';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient,HttpHeaders } from '@angular/common/http';

@Component({
  selector: 'app-specialgroup',
  templateUrl: './specialgroup.component.html',
  styleUrls: ['./specialgroup.component.scss']
})
export class SpecialgroupComponent implements OnInit {

  constructor(private cart:CartService, 
              private router:Router,
              private route: ActivatedRoute,
              private http:HttpClient) { }
  product:any
  isLoading=false
  searchIsActive=false
  specialGroupArray:any=[]
  headingData:any
  productRoute= {name:String};
  paginationDetails:any
  ngOnInit(): void {
    this.route.params.subscribe((data:any)=>{
       this.cart.getSpecialgroup(data.id).subscribe((d:any)=>{
         this.specialGroupArray=d.data.results
         this.paginationDetails=d.data
       })
    })  
  } 


  priviousData(){
    let user:any = localStorage.getItem('marketplaceUser')
    let userDetails = JSON.parse(user)
    let tokens=userDetails?.token
    let headers=new HttpHeaders({
      Authorization: ` ${tokens}`
    }) 
    this.isLoading=true
    if(this.paginationDetails?.previous!=null){
      this.http.get<any>(this.paginationDetails?.previous).subscribe((d:any)=>{
        this.specialGroupArray = d.data?.results
        this.paginationDetails=d.data
        this.isLoading=false
      }) 
    } 
      
  }

  nextData(){
    let user:any = localStorage.getItem('marketplaceUser')
    let userDetails = JSON.parse(user)
    let tokens=userDetails?.token
    let headers=new HttpHeaders({
      Authorization: ` ${tokens}`
    }) 
    this.isLoading=true
    if(this.paginationDetails?.next!=null){
      this.http.get<any>(this.paginationDetails?.next).subscribe((d:any)=>{
        this.specialGroupArray = d.data?.results
        this.paginationDetails=d.data
        this.isLoading=false
      })
    }
  }
  
}
