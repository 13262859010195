<div class="container-fluid bgw px-sm-5 px-0" *ngIf="main==true"  style="position: fixed;width: 100%;top: 0; left: 0; z-index: 999;">
    <div class=" row mx-0 py-2 px-sm-0 px-2 d-flex align-items-center justify-content-between">
        <div class=" col-5 logo px-0">
            <img src="../../../assets/icons/sidra_logo.png" alt="" [routerLink]="['/']">
        </div>
        <div class="col-7 d-flex align-items-center justify-content-sm-start justify-content-end px-0">
            <span class="fw-bold head_text"> Sell on Sidrabazar</span>
        </div>
    </div>
</div>

<div class="container-fluid over-flow  px-0 mt-5" *ngIf="main==true">
    <!-- banner -->
    <div class="banner  ">
        <!-- <img class="px-0" src="../../../assets/images/sellwithus-banner.png" alt=""> -->
        <div class="banner_text  d-flex flex-column justify-content-center ms-lg-5 ms-0 ps-sm-5 ps-3">
            <span class="banner_text1 fw-bold" data-aos="fade-up" data-aos-duration="2000">unleash success with<br>
                SidraBazar seller perks!</span>
            <span class="banner_text2 fz-16 mt-2" data-aos="fade-up" data-aos-duration="2000">List your products and
                Start Marketing Your Product Today</span>
            <button class="register_button px-5 py-2 mt-4" [routerLink]="['/sellwithus-registration']"
                data-aos="fade-up" data-aos-duration="2000">Register</button>
        </div>
    </div>


    <!-- section 1/2/3 -->
    <div class=" bgw py-sm-5 py-2">
        <div class="container-xl px-xl-0 px-sm-5 px-3">


            <!-- section 1 -->
            <div class=" col-12 row  pb-5 pt-md-5 pt-0 mx-0">

                <!-- div duplication for responsive -->
                <div class="col-12  box d-flex d-md-none pt-2 pb-3 px-0" style="height: fit-content;" data-aos="fade-up" data-aos-duration="2000">

                    <div class=" col d-flex flex-row justify-content-center ">
                        <div class="section1_round p-sm-4 p-0"></div>
                        <div class="d-flex flex-column justify-content-center align-item-center ps-2">
                            <span class="section1_no fw-bold">100+</span>
                            <span class="fz-11">Trustable Sellers</span>
                        </div>
                    </div>

                    <div class="col d-flex flex-row justify-content-center ">
                        <div class="section1_round p-sm-4 p-0"></div>
                        <div class="d-flex flex-column justify-content-center align-item-center ps-2">
                            <span class="section1_no fw-bold">25,000+</span>
                            <span class="fz-11">Products Buying</span>
                        </div>
                    </div>

                    <div class="col d-flex flex-row justify-content-center ">
                        <div class="section1_round p-sm-4 p-0"></div>
                        <div class="d-flex flex-column justify-content-center align-item-center ps-2">
                            <span class="section1_no fw-bold">43+</span>
                            <span class="fz-11"> Cities in UAE</span>
                        </div>
                    </div>
                </div>

                <!-- div duplication for responsive -->


                <div class="col-md-8  col-12 px-0 mt-md-0 mt-5 pe-md-4 pe-0" data-aos="fade-up"
                    data-aos-duration="2000">
                    <span class="section_heading fw-bold">Lets Start Selling on Sidrabazar !</span>
                    <p class="mt-3 lh-lg" style="text-align: justify;">Selling on Sidra Bazar offers businesses a
                        dynamic pathway to success in today's digital era. The far-reaching impact of online commerce
                        enables access to a global customer base, breaking down geographical barriers and opening doors
                        to unprecedented market expansion.</p>

                    <p class="mt-3 lh-lg" style="text-align: justify;">Sidra Bazar provides the advantage of
                        convenience, allowing customers to shop anytime and
                        anywhere. This accessibility, coupled with the potential for lower overhead costs, empowers
                        businesses to streamline operations and allocate resources strategically. Moreover, the
                        data-driven insights gained from ecommerce platforms empower informed decision-making,
                        facilitating personalized marketing approaches and fostering long-term customer loyalty. In a
                        world driven by digital interactions, embracing ecommerce is pivotal for businesses seeking
                        growth, competitiveness, and a lasting market presence..</p>
                </div>
                <div class=" col-md-4  col-12 d-none d-md-block justify-content-center box p-xl-5 p-lg-4 p-3 "
                    data-aos="fade-up" data-aos-duration="2000" style="height: fit-content;">
                    <div class="d-flex flex-row">
                        <div class="section1_round p-4"></div>
                        <div class="d-flex flex-column justify-content-center align-item-center ps-3">
                            <span class="section1_no fw-bold">100+</span>
                            <span class="fz-12">Trustable Sellers</span>
                        </div>
                    </div>

                    <div class="d-flex flex-row my-4">
                        <div class="section1_round p-4"></div>
                        <div class="d-flex flex-column justify-content-center align-item-center ps-3">
                            <span class="section1_no fw-bold">25,000+</span>
                            <span class="fz-12">Products Buying</span>
                        </div>
                    </div>

                    <div class="d-flex flex-row">
                        <div class="section1_round p-4"></div>
                        <div class="d-flex flex-column justify-content-center align-item-center ps-3">
                            <span class="section1_no fw-bold">43+</span>
                            <span class="fz-12">Available Cities in UAE</span>
                        </div>
                    </div>
                </div>
            </div>



            <!-- section 2 -->
            <div class="col-12 row mx-0 ">
                <span data-aos="fade-up" data-aos-duration="2000"
                    class="section_heading2 fw-bold d-flex justify-content-center align-item-center mb-3 px-0">Benefits
                    of becoming
                    a seller on Sidracart </span>


                <div
                    class="col-12 d-block d-lg-flex justify-content-center align-item-center px-0  d-sm-none d-md-none">

                    <div class="col-lg col-12  d-flex flex-column  mt-sm-0 mt-2" data-aos="fade-up" data-aos-duration="2000">
                        <div class="card first py-5 px-4">
                            <div class="overlay"></div>
                            <div class="circle">
                                <img src="../../../assets/images/Group 29196.svg" width="80px" alt="">

                            </div>
                            <span class="section2_text1 fw-bold mt-4">Wider Customer Reach:</span>
                            <span class="mt-3 lh-lg" style="text-align: center;">Sidra Bazar provide access to a global
                                audience,
                                enabling businesses to reach
                                customers beyond their local markets. This expanded reach can significantly increase
                                brand
                                exposure and sales potential.</span>
                        </div>
                    </div>

                    <div class="col-lg col-12  d-flex flex-column ms-sm-2 ms-0 mt-sm-0 mt-2" data-aos="fade-up" data-aos-duration="2000">
                        <div href="#" class="card second py-5 px-4">
                            <div class="overlay"></div>
                            <div class="circle">
                                <img src="../../../assets/images/Group 29197.svg" width="80px" alt="">

                            </div>
                            <span class="section2_text1 fw-bold mt-4">Reduced Marketing Costs:</span>
                            <span class="mt-3 lh-lg" style="text-align: center;">Ecommerce platforms often come with
                                built-in
                                marketing and advertising tools,
                                allowing you to reach your target audience more effectively and at a lower cost compared to
                                traditional advertising methods.</span>
                        </div>
                    </div>

                    <div class="col-lg col-12  d-flex flex-column ms-sm-2 ms-0 mt-sm-0 mt-2" data-aos="fade-up" data-aos-duration="2000">
                        <div href="#" class="card third py-5 px-4">
                            <div class="overlay"></div>
                            <div class="circle">
                                <img src="../../../assets/images/Group 29198.svg" width="80px" alt="">

                            </div>
                            <span class="section2_text1 fw-bold mt-4">Scalability:</span>
                            <span class="mt-3 lh-lg" style="text-align: center;">Ecommerce platforms can easily accommodate
                                business growth. As your business
                                expands, you can add new products, categories, and features without the same level of
                                complexity that physical stores might entail.</span>
                        </div>
                    </div>

                    <div class="col-lg col-12  d-flex flex-column ms-sm-2 ms-0 mt-sm-0 mt-2" data-aos="fade-up" data-aos-duration="2000">
                        <div href="#" class="card fourth py-5 px-4">
                            <div class="overlay"></div>
                            <div class="circle">
                                <img src="../../../assets/images/Group 29561.svg" width="80px" alt="">

                            </div>
                            <span class="section2_text1 fw-bold mt-4">24/7 Availability:</span>
                            <span class="mt-3 lh-lg" style="text-align: center;">Your online store is always open, providing
                                customers with the flexibility to
                                shop whenever they prefer. This can result in continuous sales and revenue even outside
                                regular business hours.</span>
                        </div>
                    </div>

                    <div class="col-lg col-12  d-flex flex-column ms-sm-2 ms-0 mt-sm-0 mt-2" data-aos="fade-up" data-aos-duration="2000">
                        <div href="#" class="card fifth py-5 px-4">
                            <div class="overlay"></div>
                            <div class="circle">
                                <img src="../../../assets/images/Group 29560.svg" width="80px" alt="">

                            </div>
                            <span class="section2_text1 fw-bold mt-4">Social Commerce Integration:</span>
                            <span class="mt-3 lh-lg" style="text-align: center;">Many ecommerce platforms allow seamless
                                integration with social media
                                platforms, enabling you to leverage social networks to promote products and engage with
                                customers directly.</span>
                        </div>
                    </div>

                </div>

                <!-- slider  -->
                <div class="uk-position-relative uk-visible-toggle uk-dark d-none d-sm-block d-md-block  d-lg-none"
                    tabindex="-1" uk-slider >

                    <ul class="uk-slider-items uk-child-width-1-2 uk-child-width-1-2@s uk-child-width-1-4@m">
                        <li>
                            <div href="#" class="card first py-5 px-4 ms-2">
                                <div class="overlay"></div>
                                <div class="circle">
                                    <img src="../../../assets/images/Group 29196.svg" width="80px" alt="">
    
                                </div>
                                <span class="section2_text1 fw-bold mt-4">Wider Customer Reach:</span>
                                <span class="mt-3 lh-lg" style="text-align: center;">Sidra Bazar provide access to a global
                                    audience,
                                    enabling businesses to reach
                                    customers beyond their local markets. This expanded reach can significantly increase
                                    brand
                                    exposure and sales potential.</span>
                            </div>
                        </li>
                        <li>
                            <div href="#" class="card second py-5 px-4 ms-2">
                                <div class="overlay"></div>
                                <div class="circle">
                                    <img src="../../../assets/images/Group 29197.svg" width="80px" alt="">
    
                                </div>
                                <span class="section2_text1 fw-bold mt-4">Reduced Marketing Costs:</span>
                                <span class="mt-3 lh-lg" style="text-align: center;">Ecommerce platforms often come with
                                    built-in
                                    marketing and advertising tools,
                                    allowing you to reach your target audience more effectively and at a lower cost compared to
                                    traditional advertising methods.</span>
                            </div>
                        </li>
                        <li>

                            <div href="#" class="card third py-5 px-4 ms-2">
                                <div class="overlay"></div>
                                <div class="circle">
                                    <img src="../../../assets/images/Group 29198.svg" width="80px" alt="">
    
                                </div>
                                <span class="section2_text1 fw-bold mt-4">Scalability:</span>
                                <span class="mt-3 lh-lg" style="text-align: center;">Ecommerce platforms can easily accommodate
                                    business growth. As your business
                                    expands, you can add new products, categories, and features without the same level of
                                    complexity that physical stores might entail.</span>
                            </div>
                        </li>
                        <li>

                            <div href="#" class="card fourth py-5 px-4 ms-2">
                                <div class="overlay"></div>
                                <div class="circle">
                                    <img src="../../../assets/images/Group 29561.svg" width="80px" alt="">
    
                                </div>
                                <span class="section2_text1 fw-bold mt-4">24/7 Availability:</span>
                                <span class="mt-3 lh-lg" style="text-align: center;">Your online store is always open, providing
                                    customers with the flexibility to
                                    shop whenever they prefer. This can result in continuous sales and revenue even outside
                                    regular business hours.</span>
                            </div>
                        </li>
                        <li>

                            <div href="#" class="card fifth py-5 px-4 ms-2">
                                <div class="overlay"></div>
                                <div class="circle">
                                    <img src="../../../assets/images/Group 29560.svg" width="80px" alt="">
    
                                </div>
                                <span class="section2_text1 fw-bold mt-4">Social Commerce Integration:</span>
                                <span class="mt-3 lh-lg" style="text-align: center;">Many ecommerce platforms allow seamless
                                    integration with social media
                                    platforms, enabling you to leverage social networks to promote products and engage with
                                    customers directly.</span>
                            </div>
                        </li>
                    </ul>

                    <a class="uk-position-center-left uk-position-small uk-hidden-hover" href="#" uk-slidenav-previous
                        uk-slider-item="previous"></a>
                    <a class="uk-position-center-right uk-position-small uk-hidden-hover" href="#" uk-slidenav-next
                        uk-slider-item="next"></a>

                </div>
            </div>




            <!-- section 3 -->

            <div class="col-12 row mx-0 py-5 my-sm-5 my-0">
                <span class="section_heading fw-bold" data-aos="fade-up" data-aos-duration="1500">Customer Benefits in
                    SidraBazar</span>
                <div class="col-md-4 col-12 d-flex flex-column pt-3 section_3_div">
                    <img src="../../../assets/images/Rectangle 22123.png" alt="" data-aos="zoom-in-up"
                        data-aos-duration="1500">
                    <span class="fz-18 fw-500 py-3" data-aos="zoom-in-up" data-aos-duration="1500">Convenience:</span>
                    <span class="lh-lg fw-400" data-aos="zoom-in-up" data-aos-duration="1500">Online shopping allows
                        customers to browse, compare, and purchase
                        products or services from the comfort of their homes, eliminating the need to visit physical
                        stores. This convenience is especially appealing for busy individuals, those with mobility
                        constraints, or people in remote areas.</span>
                </div>
                <div class="col-md-4 col-12 d-flex flex-column pt-3 section_3_div">
                    <img src="../../../assets/images/Rectangle 22124.png" alt="" data-aos="zoom-in-up"
                        data-aos-duration="2000">
                    <span class="fz-18 fw-500 py-3" data-aos="zoom-in-up" data-aos-duration="2000">Wide
                        Selection:</span>
                    <span class="lh-lg fw-400" data-aos="zoom-in-up" data-aos-duration="2000">E-commerce platforms offer
                        a vast range of products and services, often
                        much larger than what can be found in a brick-and-mortar store. This variety gives customers the
                        opportunity to explore different options, find unique items, and make more informed purchasing
                        decisions.</span>
                </div>
                <div class="col-md-4 col-12 d-flex flex-column pt-3 section_3_div">
                    <img src="../../../assets/images/Rectangle 22125.png" alt="" data-aos="zoom-in-up"
                        data-aos-duration="3000">
                    <span class="fz-18 fw-500 py-3" data-aos="zoom-in-up" data-aos-duration="3000">Fast Delivery:</span>
                    <span class="lh-lg fw-400" data-aos="zoom-in-up" data-aos-duration="3000">E-commerce offers various
                        delivery options, including expedited shipping,
                        same-day or next-day delivery, and the ability to choose specific delivery time slots. Fast and
                        flexible delivery services cater to customers' urgency and scheduling needs, enhancing their
                        overall shopping experience.</span>
                </div>
            </div>

        </div>
    </div>


    <!-- section 4 -->
    <div class="col-12 row mx-0   py-sm-5 py-4 px-sm-5 px-1" style="background-color: #FFF3E7;">

        <div class=" col-md-8 col-12 d-flex px-sm-3 px-1  justify-content-center align-items-center "
            data-aos="fade-right" data-aos-duration="2000">
            <span class="section4_text fw-500">Empower Your Brand: Elevate Your <br>
                Business with E-Com Seller Benefits!</span>
        </div>
        <div class=" col-md-4 col-12 d-flex justify-content-center align-items-center mt-md-0 mt-3" data-aos="fade-left"
            data-aos-duration="2000">
            <button class="register_button_2   py-md-3 py-2 px-md-5 px-4"
                [routerLink]="['/sellwithus-registration']">Register</button>
        </div>
    </div>

    <!-- section 5 -->
    <div class="bgw">
        <div class="container-xl px-xl-0 px-sm-5 px-3 py-sm-5 py-3">
            <div class="col-12 row mx-0 py-5">

                <!-- div duplication for responsive  -->
                <div class="col-12  d-flex d-lg-none justify-content-center align-items-center">
                    <img src="../../../assets/images/Group 29567.png" alt="" data-aos="fade-up"
                        data-aos-duration="2000">
                </div>
                <!-- div duplication for responsive  -->

                <div class="col-lg-8 col-12 d-flex flex-column">
                    <span class="fw-bold fz-18" data-aos="fade-up" data-aos-duration="2000">What is Sidra Bazar?</span>
                    <span class="py-3 lh-lg" data-aos="fade-up" data-aos-duration="2000">Sidra Bazar is an online
                        e-commerce platform that offers a wide range of
                        products, including [mention specific types of products or services here]. It provides customers
                        with a convenient way to shop for their desired items from the comfort of their homes</span>
                    <ol class="mt-0" data-aos="fade-up" data-aos-duration="2000">
                        <li class="fw-bold">
                            How do I create an account on Sidra Bazar?
                        </li>
                        <span class=" lh-lg ">To create an account on Sidra Bazar, follow these steps:</span><br>
                        <span class=" lh-lg ">a. Visit the official Sidra Bazar website.</span><br>
                        <span class=" lh-lg "> b. Click on the "Sign Up" or "Register" button.</span><br>
                        <span class=" lh-lg ">c. Fill out the required information, such as your name, email address,
                            and
                            password.</span><br>
                        <span class=" lh-lg ">d. Verify your email address through the confirmation link sent to your
                            inbox.</span><br>
                        <span class=" lh-lg ">e. Once verified, your account will be created, and you can start
                            shopping.</span>


                        <li class="fw-bold mt-2">
                            What payment methods are accepted on Sidra Bazar?
                        </li>
                        <span class=" lh-lg">Sidra Bazar accepts a variety of payment methods, including credit/debit
                            cards, PayPal, bank transfers, and other secure online payment options. Please refer to the
                            "Payment Options" section on the website for a complete list of accepted methods.</span>

                        <li class="fw-bold mt-2">
                            How can I track my order?
                        </li>
                        <span class=" lh-lg ">To track your order on Sidra Bazar, follow these steps:</span><br>
                        <span class=" lh-lg ">a. Log in to your account.</span><br>
                        <span class=" lh-lg ">b. Go to the "Order History" or "My Orders" section.</span><br>
                        <span class=" lh-lg ">c. Locate the order you want to track and click on the "Track Order" or
                            similar option.</span><br>
                        <span class=" lh-lg ">d. You will be provided with tracking information, including the
                            shipment's current status and expected delivery date.</span><br>

                        <li class="fw-bold mt-2">
                            What is Sidra Bazar's return and refund policy?
                        </li>
                        <span class=" lh-lg">Sidra Bazar has a customer-friendly return and refund policy. If you're not
                            satisfied with your purchase, you can initiate a return within [mention the specific
                            timeframe, e.g., 30 days] of receiving the item. Make sure the item is in its original
                            condition and packaging. Follow the instructions in the "Returns" section on the website for
                            step-by-step guidance on initiating a return and requesting a refund.</span>
                    </ol>
                    <span></span>
                </div>

                <div class="col-lg-4 d-none d-lg-flex align-items-center">
                    <img src="../../../assets/images/Group 29567.png" alt="" data-aos="fade-up"
                        data-aos-duration="2000">
                </div>

            </div>
        </div>
    </div>



    <!-- chatbot -->
    <div class="chatbot_1" *ngIf="chatbotbtn==true">
        <div class="d-none d-sm-flex">
            <button type="button" class="chatbot_btn px-4 py-2" (click)="chatbot()"><img class="me-2"
                    src="../../../assets/images/chatbot.svg" alt=""> May i help you</button>
        </div>

        <!-- chatbot btn mobile responsive  -->
        <div class="d-flex d-sm-none">
            <button type="button" class="chatbot_btn_r" (click)="chatbot()"><img class=""
                    src="../../../assets/images/chatbot.svg" alt=""></button>
        </div>
    </div>

    <div class="chatbot_2  " *ngIf="chatbotmsgtab==true">
        <div class=" rounded">
            <div class="chatbot_header rounded-top d-flex justify-content-between align-items-center px-2 py-1">
                <span class="fw-bold"><img class="me-2" src="../../../assets/images/chatbot.svg" alt="">Sidra Bot</span>
                <i class="bi bi-x-square cursor" (click)="close()"></i>
            </div>

            <div #chatbox class="chatbot_textarea shadow-sm" style="height: 500px; overflow: auto;">
                <ng-container *ngFor="let message of inputArray; let i = index">
                    <div *ngIf="message !== null" class="chatbot_textarea d-flex justify-content-end p-3">
                        <div class="px-3 py-2"
                            style="background-color: #FFECCF; border-radius: 8px 8px 0px 8px; max-width: 75%;">
                            <span class="input_msg">{{ message }}</span>
                        </div>
                    </div>
                    <div class="chatbot_textarea d-flex justify-content-start flex-column px-3 "
                        [class.pt-3]="message !== null" [class.pb-2]="message !== null">
                        <div class="px-3 py-2 w-75 mb-1"
                            style="background-color: #F2F2F2; border-radius: 8px 8px 8px 0px;">
                            <span class="output_msg" style="text-align: justify;">{{ outputArray[i] }}</span>
                        </div>

                        <div class="d-flex justify-content-start" *ngFor="let btn of button[i]; let j = index">
                            <button *ngIf="showAllButtons || j < initialButtonCount" class="w-75 py-1 mb-1 btn_msg"
                                (click)="chatbtnclick(i, btn.payload, btn.title)" [disabled]="buttonDisabledStates[i]">
                                {{ btn.title }}
                            </button>
                        </div>

                        <span type="button" class="show-more-button w-75" (click)="toggleShowAllButtons()"
                            *ngIf="button[i].length > 3">
                            {{ showAllButtons ? 'Show Less' : 'Show More' }}
                        </span>

                    </div>
                </ng-container>
                <!-- <img *ngIf="loading" src="../../../assets/images/Animation - 1693981992613.gif" alt="Loading..." width="80" /> -->
            </div>

            <div class="form-control d-flex justify-content-between rounded-bottom shadow-sm"
                style="border-radius: 0px;border: none; border-top: 1px;">

                <input type="text" placeholder="Text your message ..." class="form-control fz-13 chatbot-textarea"
                    [(ngModel)]="input_send" style="border: none;" (keydown.enter)="send()">

                <div class="d-flex justify-content-center cursor" (click)="send()" style="border-radius: 50%;">
                    <img src="../../../assets/images/send.svg" width="20px" alt="">
                </div>
            </div>

        </div>
    </div>

</div>


<!-- chatbot mobile responsive  -->

<div class="chatbot  " *ngIf="chatbotmsgtab==true">
    <div class="d-flex d-sm-none ">
        <div class="chatbot_header_r  d-flex justify-content-between align-items-center px-3 py-1">
            <span class="fw-bold"><img class="me-2" src="../../../assets/images/chatbot.svg" alt="">Sidra Bot</span>
            <i class="bi bi-x-square cursor" (click)="close()"></i>
        </div>

        <div #chatbox class="chatbot_textarea shadow-sm py-5" style="height: 98vh; overflow: auto; width: 100%;">
            <ng-container *ngFor="let message of inputArray; let i = index">
                <div *ngIf="message !== null" class="chatbot_textarea d-flex justify-content-end p-3">
                    <div class="p-3"
                        style="background-color: #FFECCF; border-radius: 15px 15px 0px 15px; max-width: 75%;">
                        <span class="input_msg">{{ message }}</span>
                    </div>
                </div>
                <!-- <img src="../../../assets/images/Animation - 1693981992613.gif" alt="Loading..." width="80" /> -->
                <div class="chatbot_textarea d-flex justify-content-start flex-column px-3"
                    [class.pt-3]="message !== null" [class.pb-2]="message !== null">
                    <div class="p-3 w-75 mb-1" style="background-color: #F2F2F2; border-radius: 15px 15px 15px 0px;">
                        <span class="output_msg">{{ outputArray[i] }}</span>
                    </div>

                    <div class="d-flex justify-content-start" *ngFor="let btn of button[i]; let j = index">
                        <!-- Use ngIf to conditionally display buttons based on the showAllButtons flag -->
                        <button *ngIf="showAllButtons || j < initialButtonCount" class="w-75 py-1 mb-1 btn_msg"
                            [disabled]="buttonDisabledStates[i]" (click)="chatbtnclick(i, btn.payload, btn.title)">
                            {{ btn.title }}
                        </button>
                    </div>

                    <span type="button" class="show-more-button w-75" (click)="toggleShowAllButtons()"
                        *ngIf="button[i].length > 4">
                        {{ showAllButtons ? 'Show Less' : 'Show More' }}
                    </span>
                </div>
            </ng-container>
        </div>

        <div class="text_input form-control d-flex justify-content-between rounded-bottom shadow-sm"
            style="border-radius: 0px;border: none; border-top: 1px;">

            <input type="text" placeholder="Text your message ..." class="form-control fz-13 chatbot-textarea"
                [(ngModel)]="input_send" style="border: none;" (keydown.enter)="send()">

            <div class="d-flex justify-content-center cursor" (click)="send()" style="border-radius: 50%;">
                <img src="../../../assets/images/send.svg" width="20px" alt="">
            </div>
        </div>

    </div>
</div>

<img src="" alt="">
<app-footer3 *ngIf="main==true"></app-footer3>