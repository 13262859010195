import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { ProductRealChild } from 'src/app/models/product';
import { CartService } from 'src/app/services/cart.service';
import { MessengerService } from 'src/app/services/messenger.service';
import { WishlistService } from 'src/app/services/wishlist.service';

@Component({
  selector: 'app-product-card-store',
  templateUrl: './product-card-store.component.html',
  styleUrls: ['./product-card-store.component.scss']
})
export class ProductCardStoreComponent implements OnInit {
  @Input() products!: ProductRealChild;
  @Input() wishlist = false
  @Input () control= true
  @Input() wishListed = false
  @Output() cartEvent = new EventEmitter()
  @Output () wishEvent = new EventEmitter()
  @Output () isWishListEvent = new EventEmitter()
  @Input() block_design:any
  userDetails:any
  wishListActived=false
  is_wishlisted=false
  currentQty = 1
  inCartIsActive=false
  is_addtocart=true
  withAndWithoutLoginBtn=false
  allCartProduct:any=[]
  maxQty:any
  minMumQty:any
  withLoginwishList=false
  groupListArray:any=[]
  isGroupActive=false
  isDefaultActive=false
  wishlistArray:any=[]
  inCart=false
  wishListId:any
  dataKey="cartData"
  withOutLoginArray:any=[]
  is_wish_active:any
  dot:boolean=false
  constructor(private cart:CartService,
              private messageService:MessengerService,
              private wish:WishlistService,
              private router:Router) { }

              ngOnInit(){
                let user:any = localStorage.getItem('marketplaceUser')
                this.userDetails = JSON.parse(user)
                let min:any
                let max:any
                if ((this.products?.min_order_limit != null && this.products?.min_order_limit != 0) || (this.products?.max_order_limit != null && this.products?.max_order_limit != 0)) {
                  min  = (this.products?.min_order_limit != null && this.products?.min_order_limit != 0) ? this.products?.min_order_limit : 1 
                  max  = (this.products?.max_order_limit != null && this.products?.max_order_limit  != 0 && this.products?.max_order_limit  > this.products?.stock_count) ? this.products?.stock_count : this.products?.max_order_limit 
                }else{
                  max   = (this.products?.max_order_limit  == null || this.products?.max_order_limit  == 0 ) ? (this.products?.stock_count > 0) ? this.products?.stock_count : 10 : 10
                  min = 1
                } 
                max = this.products?.max_order_limit > 10 ? 10 : this.products?.max_order_limit 
                this.currentQty=min 
                this.maxQty=max
                this.minMumQty=min 
                if(this.userDetails!=null){
                  this.withLoginwishList=true
                  this.withAndWithoutLoginBtn=true
                  this.wish.getWishListGroup().subscribe((d:any)=>{
                    this.groupListArray=d.data.results
                    if(this.groupListArray.length!=0){
                      this.isGroupActive=true
                    }else{ 
                      this.isGroupActive=false
                    }
                    if(this.groupListArray.length==1){
                      let name=null
                      name=this.groupListArray.filter((d:any)=>d.group_name=="default")
                      if(name!=null){
                        this.isDefaultActive=true
                      }else{
                        this.isDefaultActive=false
                      }
                    }
                    
                  })
                  this.wish.userWishListId().subscribe((d:any)=>{
                    this.wishlistArray=d.data?.added_list
                    this.wishListId=this.wishlistArray.some((item:any)=>item==this.products.id)
                    if(this.wishListId==true){
                      this.is_wishlisted=true
                    }else{
                      this.is_wishlisted=false
                    }
                    
                  })
                  this.cart.getCartProduct().subscribe((data:any)=>{
                    this.allCartProduct=data.data?.added_list
                    this.inCart=this.allCartProduct.some((item:any)=>item==this.products.id)
                    if(this.inCart==true){
                      this.inCartIsActive=true
                      this.is_addtocart=false
                    }
                    else{
                      this.inCartIsActive=false
                      this.is_addtocart=true
                    } 
                  })
                }else{
                  this.withLoginwishList=false
                  this.withAndWithoutLoginBtn=false
                  let history:any =  localStorage.getItem(this.dataKey)
                  this.withOutLoginArray = JSON.parse(history)
                  if(this.withOutLoginArray!=null){
                    this.inCart=this.withOutLoginArray.some((item:any)=>item.variant_id==this.products.id)
                  }
                  if(this.inCart==true){
                    this.inCartIsActive=true
                    this.is_addtocart=false
                    this.withAndWithoutLoginBtn=true
                  } 
                  else{
                    this.inCartIsActive=false
                    this.is_addtocart=true 
                    this.withAndWithoutLoginBtn=false
                  }
                } 
                this.wishListActived=true
              }


  addToCart(prod:any){
    this.dot=true
    // console.log("prod",prod);
    
    this.cart.addToCart(prod,this.currentQty) 
    this.messageService.get().subscribe((d:any)=>{
      if(d=='success'){
        this.dot=false
        this.inCartIsActive=true
        this.is_addtocart=false
      }else{
        this.dot=false
      }
    })
  }

  addToCartLocal(prod:any){
    this.inCartIsActive=true
    this.is_addtocart=false
    this.cart.addToLocalCart(prod,this.currentQty) 
  }

  movetoAnother(item:any,id:any,index:any){
    // this.ismove_active=true
    let wish1={
      variant_id:item.id,
      group_id:id,
      varient_code:item.code,
      inventory_code:item.inventory_id
    }
    this.wish.createWishlist(wish1) 
    this.is_wish_active=index  
    this.is_wishlisted=true
    this.wishListActived=false
  } 

  wishListdropDown(item:any){
    if(this.is_wishlisted==true){
      this.wishListActived=false
      this.wish.unWishlisted(this.products.id)
      this.messageService.getWish().subscribe((d:any)=>{
        if(d=='success'){
          this.is_wishlisted=false
          this.is_wish_active=null
        } 
      }) 
    }
    else if(this.is_wishlisted==false&&this.isGroupActive==false){ 
      this.wishListActived=true
      this.is_wish_active=null
      this.is_wishlisted=true
      let name=null
      name=this.groupListArray.filter((d:any)=>d.group_name=="default")
      this.wishListActived=false
      this.is_wish_active=null
      let wish1:any={
        variant_id:item.id,
        group_id:null,
        varient_code:item.code,
        inventory_code:item.inventory_id
      }
      this.wish.createWishlist(wish1) 
      // this.is_wish_active=index  
    } 
    else if(this.is_wishlisted==false&&this.isGroupActive==true&&this.isDefaultActive==false){ 
      this.wishListActived=true
      this.is_wish_active=null
    } 
    else if(this.is_wishlisted==false&&this.isGroupActive==true&&this.isDefaultActive==true){ 
      let name=null
      name=this.groupListArray.filter((d:any)=>d.group_name=="default")
      this.wishListActived=false
      this.is_wish_active=null
      let wish1:any={
        variant_id:item.id,
        group_id:name[0]?.id,
        varient_code:item.code,
        inventory_code:item.inventory_id
      }
      this.wish.createWishlist(wish1) 
      // this.is_wish_active=index  
      this.is_wishlisted=true
      this.wishListActived=false
    } 
  } 

  navigateProductPage(id:any){
    this.router.navigate(['/product/' + id])
  } 
}
