import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-termscodition',
  templateUrl: './termscodition.component.html',
  styleUrls: ['./termscodition.component.scss']
})
export class TermscoditionComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
