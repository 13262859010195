

  <app-sidraheader style="position: fixed;width: 100%;top: 0;
  left: 0;
  z-index: 999;"></app-sidraheader>
  <app-menu-header></app-menu-header>
  <div class="container-fluid px-4">
      <div class="col-12 row mx-0 d-flex justify-content-center align-items-center px-4 my-3" style="background-color: white;height: 70vh;">
        <div class="col-6 d-flex flex-row">
            <div class="d-flex align-items-center">
                <img src="../../../assets/icons/noproducts.svg" alt="" width="250">
            </div>
            <div class="d-flex flex-column justify-content-center ms-3 mb-4">
                <h1 class="mb-0">
                    We Couldn't find any matches!
                </h1>
                <span class="fz-11 mt-3">
                    Looks like you entered a page which doesn’t exist anymore. Let’s take you back to home.
                </span>
                <div class="mt-3 ">
                    <button class="fz-13 fw-500 update-btn py-2 px-2" [routerLink]="['/']" routerLinkActive="router-link-active" >
                      Return to Home
                    </button>
                </div>
            </div>
        </div>
      </div>
  </div>
  
  <app-footer></app-footer>