<!-- Popular Category -->
<app-popular-category
group="Life Style"
[count]="count"
[popular_category]="popular_category"
>
</app-popular-category>
<!-- Ends of Popular Category -->

<app-deals 
group="Life Style"
[parent]="deals"
></app-deals>

<app-product-slider-two 
[sectionTitle]="today.sectionTitle"
[products]="today.products"
></app-product-slider-two>

<app-personal-store
[storeBG]="personal_store.storeBG"
[storeTitle] ="personal_store.storeTitle"
[personalStore] = "personal_store.personalStore"
></app-personal-store>

<app-product-slider-two 
[sectionTitle]="today.sectionTitle"
[products]="today.products"
></app-product-slider-two>

<app-product-slider-two 
[sectionTitle]="today.sectionTitle"
[products]="today.products"
></app-product-slider-two>

<app-add-slider
[type]="type_1"
[addList]="addType_1"
></app-add-slider>

<!-- product slider Section -->
<div class="container-fluid d-flex flex-wrap mt-3 p-3 w-100">
<h4 class="fw-bold min-fit-width col-12 mb-4 ms-2 ms-md-0 ps-3 fz-22">Gadgets bazaar!</h4>
<div class="col-12 multi-slider">
    <div class="uk-position-relative uk-visible-toggle uk-dark px-10px" tabindex="-1" uk-slider>
        <ul class="uk-slider-items uk-child-width-1-1 uk-child-width-1-2@sm uk-child-width-1-2@s uk-child-width-1-3@m uk-child-width-1-4@l uk-child-width-1-5@xl">
            <li class="pb-3 border-0 mx-auto d-flex">
                <div class="ps-1 col-6">
                    <img class="object-fit w-100" src="../../assets/assets/fashion.png">
                </div>
                <div class="ps-1 col-6">
                    <img class="object-fit w-100" src="../../assets/assets/fashion2.png">
                </div>
            </li>
            <li class="pb-3 border-0 mx-auto d-flex">
                <div class="ps-1">
                    <img class="object-fit" src="../../assets/assets/fashion3.png">
                </div>
                <div class="ps-1">
                    <img class="object-fit" src="../../assets/assets/fashion.png">
                </div>
            </li>
            <li class="pb-3 border-0 mx-auto d-flex">
                <div class="ps-1">
                    <img class="object-fit" src="../../assets/assets/fashion2.png">
                </div>
                <div class="ps-1">
                    <img class="object-fit" src="../../assets/assets/fashion4.png">
                </div>
            </li>
            <li class="pb-3 border-0 mx-auto d-flex">
                <div class="ps-1">
                    <img class="object-fit" src="../../assets/assets/fashion5.png">
                </div>
                <div class="ps-1">
                    <img class="object-fit" src="../../assets/assets/fashion.png">
                </div>
            </li>
            <li class="pb-3 border-0 mx-auto d-flex">
                <div class="ps-1">
                    <img class="object-fit" src="../../assets/assets/fashion2.png">
                </div>
                <div class="ps-1">
                    <img class="object-fit" src="../../assets/assets/fashion4.png">
                </div>
            </li>
            <li class="pb-3 border-0 mx-auto d-flex">
                <div class="ps-1">
                    <img class="object-fit" src="../../assets/assets/fashion5.png">
                </div>
                <div class="ps-1">
                    <img class="object-fit" src="../../assets/assets/fashion.png">
                </div>
            </li>
        </ul>
        <a class="uk-position-center-left uk-position-small" href="#" uk-slidenav-previous uk-slider-item="previous"></a>
        <a class="uk-position-center-right uk-position-small" href="#" uk-slidenav-next uk-slider-item="next"></a>
    </div>
</div>
</div>

<app-add-slider
[type]="type_5"
[addList]="addType_5"
></app-add-slider>

<div class="container-fluid d-flex flex-wrap mt-3 p-3 w-100">
<h4 class="fw-bold min-fit-width col-12 mb-4 ms-2 ms-md-0 ps-3 fz-22">Pick your fashion</h4>
<div class="col-12 multi-slider">
    <div class="uk-position-relative uk-visible-toggle uk-dark px-10px" tabindex="-1" uk-slider>
        <ul class="uk-slider-items uk-child-width-1-1 uk-child-width-1-2@sm uk-child-width-1-2@s uk-child-width-1-3@m uk-child-width-1-4@l uk-child-width-1-5@xl">
            <li class="pb-3 border-0 mx-auto d-flex">
                <div class="ps-1 col-6">
                    <img class="object-fit w-100" src="../../assets/assets/fashion.png">
                </div>
                <div class="ps-1 col-6">
                    <img class="object-fit w-100" src="../../assets/assets/fashion2.png">
                </div>
            </li>
            <li class="pb-3 border-0 mx-auto d-flex">
                <div class="ps-1">
                    <img class="object-fit" src="../../assets/assets/fashion3.png">
                </div>
                <div class="ps-1">
                    <img class="object-fit" src="../../assets/assets/fashion.png">
                </div>
            </li>
            <li class="pb-3 border-0 mx-auto d-flex">
                <div class="ps-1">
                    <img class="object-fit" src="../../assets/assets/fashion2.png">
                </div>
                <div class="ps-1">
                    <img class="object-fit" src="../../assets/assets/fashion4.png">
                </div>
            </li>
            <li class="pb-3 border-0 mx-auto d-flex">
                <div class="ps-1">
                    <img class="object-fit" src="../../assets/assets/fashion5.png">
                </div>
                <div class="ps-1">
                    <img class="object-fit" src="../../assets/assets/fashion.png">
                </div>
            </li>
            <li class="pb-3 border-0 mx-auto d-flex">
                <div class="ps-1">
                    <img class="object-fit" src="../../assets/assets/fashion2.png">
                </div>
                <div class="ps-1">
                    <img class="object-fit" src="../../assets/assets/fashion4.png">
                </div>
            </li>
            <li class="pb-3 border-0 mx-auto d-flex">
                <div class="ps-1">
                    <img class="object-fit" src="../../assets/assets/fashion5.png">
                </div>
                <div class="ps-1">
                    <img class="object-fit" src="../../assets/assets/fashion.png">
                </div>
            </li>
        </ul>
        <a class="uk-position-center-left uk-position-small" href="#" uk-slidenav-previous uk-slider-item="previous"></a>
        <a class="uk-position-center-right uk-position-small" href="#" uk-slidenav-next uk-slider-item="next"></a>
    </div>
</div>
</div>

<app-add-slider
[type]="type_5"
[addList]="addType_5"
></app-add-slider>

<div class="container-fluid d-flex flex-wrap mt-3 p-3 w-100">
<h4 class="fw-bold min-fit-width col-12 mb-4 ms-2 ms-md-0 ps-3 fz-22">Cosmetics you need</h4>
<div class="col-12 multi-slider">
    <div class="uk-position-relative uk-visible-toggle uk-dark px-10px" tabindex="-1" uk-slider>
        <ul class="uk-slider-items uk-child-width-1-1 uk-child-width-1-2@sm uk-child-width-1-2@s uk-child-width-1-3@m uk-child-width-1-4@l uk-child-width-1-5@xl">
            <li class="pb-3 border-0 mx-auto d-flex">
                <div class="ps-1 col-6">
                    <img class="object-fit w-100" src="../../assets/assets/fashion.png">
                </div>
                <div class="ps-1 col-6">
                    <img class="object-fit w-100" src="../../assets/assets/fashion2.png">
                </div>
            </li>
            <li class="pb-3 border-0 mx-auto d-flex">
                <div class="ps-1">
                    <img class="object-fit" src="../../assets/assets/fashion3.png">
                </div>
                <div class="ps-1">
                    <img class="object-fit" src="../../assets/assets/fashion.png">
                </div>
            </li>
            <li class="pb-3 border-0 mx-auto d-flex">
                <div class="ps-1">
                    <img class="object-fit" src="../../assets/assets/fashion2.png">
                </div>
                <div class="ps-1">
                    <img class="object-fit" src="../../assets/assets/fashion4.png">
                </div>
            </li>
            <li class="pb-3 border-0 mx-auto d-flex">
                <div class="ps-1">
                    <img class="object-fit" src="../../assets/assets/fashion5.png">
                </div>
                <div class="ps-1">
                    <img class="object-fit" src="../../assets/assets/fashion.png">
                </div>
            </li>
            <li class="pb-3 border-0 mx-auto d-flex">
                <div class="ps-1">
                    <img class="object-fit" src="../../assets/assets/fashion2.png">
                </div>
                <div class="ps-1">
                    <img class="object-fit" src="../../assets/assets/fashion4.png">
                </div>
            </li>
            <li class="pb-3 border-0 mx-auto d-flex">
                <div class="ps-1">
                    <img class="object-fit" src="../../assets/assets/fashion5.png">
                </div>
                <div class="ps-1">
                    <img class="object-fit" src="../../assets/assets/fashion.png">
                </div>
            </li>
        </ul>
        <a class="uk-position-center-left uk-position-small" href="#" uk-slidenav-previous uk-slider-item="previous"></a>
        <a class="uk-position-center-right uk-position-small" href="#" uk-slidenav-next uk-slider-item="next"></a>
    </div>
</div>
</div>


<div class="py-3 w-100"></div>