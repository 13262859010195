<!-- product slider Section -->

<div class="container-fluid d-flex flex-wrap mt-md-3 py-3 w-100 box px-3" dir="ltr">
    <h4 class="fw-bold min-fit-width col-12 mb-0 pb-3 ms-2 ms-md-0 fz-16 border-bottom">{{sectionTitle}}</h4>
    <div class="col-12 multi-slider " dir="ltr"> 
        <div class="uk-position-relative uk-visible-toggle uk-light" tabindex="-1" uk-slider>
            <ul class="uk-slider-items uk-child-width-1-2 uk-child-width-1-3@s uk-child-width-1-6@m">
                <li *ngFor="let product of products">
                    <app-product-card-one 
                        [products]="product"    
                    ></app-product-card-one>
                </li>
            </ul> 
            <!-- <a class="uk-position-center-left uk-position-small uk-hidden-hover" href="#" uk-slidenav-previous uk-slider-item="previous"></a>
            <a class="uk-position-center-right uk-position-small uk-hidden-hover" href="#" uk-slidenav-next uk-slider-item="next"></a> -->
        
        </div>
    </div> 
</div> 

<!-- End product slider Section -->