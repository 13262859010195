import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-sellwithus',
  templateUrl: './sellwithus.component.html',
  styleUrls: ['./sellwithus.component.scss']
})
export class SellwithusComponent implements OnInit {
  @ViewChild('chatbox', { static: false }) chatbox: any;
  main=true
  chatbotbtn = true
  chatbotmsgtab = false
  inputArray: any[] = [];
  outputArray: any[] = [];
  input_send: any = "hi"
  button: any[] = [];
  buttonDisabledStates: boolean[] = [];
  loading: boolean = false;
  initialButtonCount: number = 4;
showAllButtons: boolean = false;

api_chatbot:any="https://sellerbot.sidrabazar.com/webhooks/rest/webhook"
  constructor(private http: HttpClient,
    private toaster: ToastrService, private auth: AuthService,
    private renderer: Renderer2,
    private el: ElementRef) { }

  ngOnInit(): void {
    window.scrollTo(0, 0);
    const element = this.el.nativeElement;
    element.scrollIntoView({ behavior: 'smooth' });
  }

  ngAfterViewInit(){
    window.scrollTo(0, 0);
  }


  chatbot() {
    this.chatbotbtn = false
    const screenWidth = window.innerWidth;
    if (screenWidth < 576) { 
      this.main = false;
    }
    this.chatbotmsgtab = true
    if (this.input_send) { 
      this.inputArray.push(this.input_send);
      let input = {
        message: this.input_send,
      }
      this.http.post(this.api_chatbot,input).toPromise().then((d: any) => {
       
        if (d[0].text) {
          this.outputArray.push(d[0].text);
          this.button.push(d[0].buttons || [])
          // this.scrollChatboxToBottom();
          setTimeout(() => {
            this.scrollChatboxToBottom();
          }, 0.500);
        } else {

        }
      })
      this.input_send = ''; // Clear the input field after pushing
    }
  
  }


  close() {
    this.chatbotbtn = true
    this.chatbotmsgtab = false
    this.main=true
  }

  send() {
    if (this.input_send) { // Make sure the input is not empty
      this.inputArray.push(this.input_send);
      let input = {
        message: this.input_send,
      }
      // this.loading = true;
      this.http.post(this.api_chatbot, input).toPromise().then((d: any) => {
      
        if (d[0].text) {
          this.outputArray.push(d[0].text);
          this.button.push(d[0].buttons || [])
          // this.scrollChatboxToBottom();
          setTimeout(() => {
            this.scrollChatboxToBottom();
          }, 0.500);
        }
        if (d[1].text){
          this.inputArray.push(null);
          this.outputArray.push(d[1].text);
          this.button.push(d[1].buttons || [])
          setTimeout(() => {
            this.scrollChatboxToBottom();
          }, 0.500);
        }else{
  
        }
        // this.loading = false;
      })
      this.input_send = ''; // Clear the input field after pushing
    }
    
  }

  chatbtnclick(i: number, btn: any, btninput: any) {
    this.inputArray.push(btninput);
  //  this.loading = true;
  for (let j = 1; j < i; j++) {
    this.buttonDisabledStates[j] = true;
  }
    let input = {
      message: btn,
    }
    // this.loading = true;
    this.http.post(this.api_chatbot, input).toPromise().then((d: any) => {
      
      if (d[0].text) {
        this.outputArray.push(d[0].text);
        this.button.push(d[0].buttons || [])
        this.buttonDisabledStates.push(true);
        setTimeout(() => {
          this.scrollChatboxToBottom();
        }, 0.500);
      } 
       if (d[1].text){
        this.inputArray.push(null);
        this.outputArray.push(d[1].text);
        this.button.push(d[1].buttons || [])
        this.buttonDisabledStates.push(true);;
        setTimeout(() => {
          this.scrollChatboxToBottom();
        }, 0.500);
      }else{

      }
      // this.loading = false;
    })
   
  }
  
  scrollChatboxToBottom() {
    const chatbox = this.chatbox.nativeElement;
    chatbox.scrollTop = chatbox.scrollHeight; // Scroll to the bottom
  }
  toggleShowAllButtons() {
    this.showAllButtons = !this.showAllButtons;
  }
}
