<div class="container-fluid">
    <div class="col-12 d-flex justify-content-center py-5">
        <div class="login-page d-flex flex-wrap bw d-flex flex-column justify-content-center">
            <div class="d-flex justify-content-center">
                <span class="fz-22 fw-500">Login to your account</span>
            </div>
            <div class="d-flex justify-content-center my-0 py-0">
                <span class="fz-13 c-585858 mt-2">Long time no see! Let’s login to get started</span>
            </div>
            <div class="mt-4 mb-3">
                <input type="email" class="form-control fz-12 h-55" id="exampleFormControlInput1" placeholder="Pone or Email">
            </div>
            <div class="mt-2">
                <input type="email" class="form-control fz-12 h-55" id="exampleFormControlInput1" placeholder="password">
            </div>
            <div class="d-flex justify-content-end mt-2 mb-3">
                <span class="fz-14 fw-bold">Forgot Password ?</span>
            </div>
            <button class="login-btn fz-15 fw-400">Login to your Account</button>
            <div class="mt-3 c-5F5F61 fz-12 l-h">
                <span class="c-5F5F61 fz-12 l-h">By continuing you agree to our</span> <i class="c-FF9900 fz-12 mx-1">Terms and Conditions</i>
                 and our  <i class="c-FF9900 fz-12"> Privacy Policy.</i> 
            </div>
            <hr>
            <div class="">
                <span class="fz-13 fw-500 c-5F5F61">Dont have an Account? <i class="fz-13 fw-500 c-FF9900 ms-1">Create an Account</i></span>
            </div>
        </div>
    </div>
</div>