<div class="container-fluid pgw">
    <div class=" row mx-0 py-2 px-sm-0 px-1 d-flex align-items-center justify-content-between">
            <div class=" col-sm-4 col-5 logo">
                <img src="../../../assets/icons/sidra_logo.png"  alt="" [routerLink]="['/']">
            </div>
            <div class="col-sm-4 col-3 d-flex align-items-center justify-content-center">
                <span class="career cursor fw-bold fz-20 d-none d-sm-block">  Terms of use</span>
            </div>
            <div class="col-4"></div>
    </div>
</div>

<div class="container-fluid">
    <div class="container my-4 px-sm-5 px-0">
        <div class="col-12 row mx-0 pgw px-3">
            <div class="py-3 fz-16 fw-bold" style="color: #717478;">
                Terms of use
            </div>
        </div>  
        <div class="col-12 row mx-0 mt-2 pgw py-4 px-3" *ngFor="let item of Data.data">
            <div *ngFor="let d of item.values" [attr.id]="d.group_key">
                <h2>{{ d.title }}</h2>
                <p class="fz-13" style="line-height: 1.5rem; text-align: justify;" [innerHTML]="d.description"></p>
            </div>
        </div>
        <!-- <div class="col-12 row mx-0 mt-2 pgw py-4 px-3" *ngFor="let d of Data.data[1].values" [attr.id]="d.group_key">
            <h2>{{d.title}}</h2>
            <p class="fz-13" style="line-height:1.5rem; text-align: justify;" [innerHTML]="d.description"></p>
        </div> -->

    </div>
</div>
<!-- footer  -->
<div class="container-fluid mx-0 py-4 footer">
    <div class="col-12 row px-lg-5 ms-1">
        <div class=" col-md-3 col-12 d-flex align-items-center justify-content-md-start justify-content-center">
            <img src="../../../assets/icons/Sidra Logo white-16 (1).svg" width="100px" alt="">
        </div>
        <div class="col-md-6 col-12 d-flex align-items-center justify-content-center mt-md-0 mt-3 px-0">
            <span class="copyright"> © 2022 Sidracartuae.com All Rights Reserved</span>
            <span class="privacytag ms-2 cursor d-none d-md-flex" [routerLink]="['/genaral-policy']">privacy & policy</span>
            <span class="privacytag ms-2 cursor d-none d-md-flex" [routerLink]="['/termsanduse']">Terms-use</span>
        </div>
        <div class="col-12 d-flex d-md-none align-items-center justify-content-center mt-1">
            <span class="privacytag ms-2 cursor" [routerLink]="['/genaral-policy']">privacy & policy</span>
            <span class="privacytag ms-2 cursor" [routerLink]="['/termsanduse']">Terms-use</span>
        </div>
        <div class="col-md-3 col-12 d-flex align-items-center justify-content-md-end justify-content-center  mt-md-0 mt-3">
            <button class="iconborder px-2 me-2"><span uk-icon="twitter"></span></button>
            <button class="iconborder px-2 me-2"><span uk-icon="facebook"></span></button>
            <button class="iconborder px-2 me-2"><span uk-icon="linkedin"></span></button>
            <button class="iconborder px-2 "><span uk-icon="instagram"></span></button>
        </div>
    </div>
</div>