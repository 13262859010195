<!-- 5 add Section slider -->
<div class="container-fluid w-100 py-3 bg-white">
    <div class="uk-position-relative uk-visible-toggle uk-light" tabindex="-1" uk-slider="autoplay: true; autoplay-interval:4000">

        <ul class="uk-slider-items " [ngClass]="type_value">
            <li class="p-1" *ngFor="let add of addList">
                <img class="w-100" [src]="add.image" [alt]="add.alt">
            </li>
        </ul>
    
        <a class="uk-position-center-left uk-position-small uk-hidden-hover" href="#" uk-slidenav-previous uk-slider-item="previous"></a>
        <a class="uk-position-center-right uk-position-small uk-hidden-hover" href="#" uk-slidenav-next uk-slider-item="next"></a>
    
    </div>
</div>
<!-- End 5 add section -->


