<!-- Banner -->
<div  class="container-fluid d-flex p-0 w-100 banner">
    <div id="carouselExampleControls" [dir]="directions" class="carousel slide w-100" data-bs-ride="carousel">
        <div class="carousel-inner w-100">
            <div class="carousel-item active">
                <img src="../../../assets/assets/bannerOne.svg" class="d-block w-100" alt="...">
              </div>
        </div> 
        <button class="carousel-control-prev"  type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
            <span class="carousel-control-prev-icon cursol_btn d-md-flex d-none" aria-hidden="false"></span>
            <span class="visually-hidden">Previous</span>
        </button>
        <button class="carousel-control-next"  type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
            <span class="carousel-control-next-icon cursol_btn d-md-flex d-none" aria-hidden="false"></span>
            <span class="visually-hidden">Next</span>
        </button>
        <div class="carousel-indicators" [dir]="directions">
            <button type="button" data-bs-target="#carouselExampleControls" *ngFor="let banner of bannerList; let i = index" [attr.data-bs-slide-to]="i" [ngClass]="{active: i==0}" aria-current="true" aria-label="Slide 1"></button>
        </div> 
    </div>
</div>

<!-- <div id="carouselExampleIndicators" class="carousel slide" data-bs-ride="carousel">
  <div class="carousel-indicators">
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
  </div>
  <div class="carousel-inner">
    <div class="carousel-item active">
      <img src="../../../assets/assets/bannerOne.svg" class="d-block w-100" alt="...">
    </div>
  </div>
  <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Previous</span>
  </button>
  <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Next</span>
  </button>
</div> -->
