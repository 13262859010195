import { HttpClient } from '@angular/common/http';
import { Component, OnInit, AfterViewInit, Renderer2, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PolicysService } from 'src/app/services/policys.service'

@Component({
  selector: 'app-termsofsales',
  templateUrl: './termsofsales.component.html',
  styleUrls: ['./termsofsales.component.scss']
})
export class TermsofsalesComponent implements OnInit,AfterViewInit {
  termsSales:any = {}
  Data:any
  constructor(  private policy: PolicysService,
    private http: HttpClient,
    private route: ActivatedRoute,
    private router: Router,
    private renderer: Renderer2,
    private el: ElementRef) { }

    ngAfterViewInit() {
      // Scroll to the fragment identifier if present in the URL
      this.route.fragment.subscribe(fragment => {
        if (fragment) {
          this.scrollToSection(fragment);
        }
      });
    }
  ngOnInit(): void {
    let privacy = this.policy.getPolicy()
    privacy.subscribe((data:any)=>{
      let privacyArray = data.data
      privacyArray.filter((data:any)=>{
        if(data.title == "TERMS OF SALE"){
          this.termsSales = data
        } 
      })
    })

    this.http.get("https://api-uat-system-architecture.sidrabazar.com/policy/policies-by-group?key=user-policy").toPromise().then((data:any)=>{
    console.log("1",data);
    this.Data=data
    console.log("DataDataData",this.Data);
    }) 


  }

  scrollToSection(fragment: string) {
    console.log("fragment",fragment);
    
    // Use Renderer2 to scroll to the specified fragment
    const element = this.el.nativeElement.querySelector(`#${fragment}`);
    console.log("element",element);
    
    if (element) {
      this.renderer.setProperty(element, 'scrollTop', 0);
      element.scrollIntoView({ behavior: 'smooth' });
    }
    console.log("element after scrollIntoView", element);
  }

}
