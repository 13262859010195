
<!-- product slider Section -->
<div class="container-fluid d-flex flex-wrap mt-md-3 p-md-3 p-1 py-3 w-100" dir="ltr">
  <h4 class="fw-bold min-fit-width col-12 mb-4 ms-2 ms-md-0 ps-md-3 px-1 fz-15 title-font">{{sectionTitle}}</h4>
  <div class="col-12 multi-slider pe-3" dir="ltr"> 
      <div class="uk-position-relative uk-visible-toggle uk-dark px-10px" tabindex="-1" uk-slider="sets: true">
          <ul class="uk-slider-items uk-child-width-1-1 uk-child-width-1-2@sm uk-child-width-1-3@s uk-child-width-1-4@m uk-child-width-1-4@l uk-child-width-1-4@xl">
              <li class="border p-2 mx-1 d-flex slider-item" dir="rtl"  *ngFor="let product of cartProductImage">
                <app-product-card-three
                  [products]="product"
                ></app-product-card-three>
              </li>
          </ul>  
          <a class="uk-position-center-left uk-position-small" href="#" uk-slidenav-previous uk-slider-item="previous"></a>
          <a class="uk-position-center-right uk-position-small" href="#" uk-slidenav-next uk-slider-item="next"></a>
      </div>
  </div> 
</div> 
<!-- End product slider Section -->

