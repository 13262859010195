import { Component, OnInit, Input, Output, EventEmitter  } from '@angular/core';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent implements OnInit {
  @Input() totalItems: any;
  @Input() itemsPerPage: any;
  @Output() pageChanged = new EventEmitter<number>();
  constructor() { }
  currentPage = 1;

  totalPages: any;
  pageNumbers: number[] = [];

  ngOnInit() {
    this.calculateTotalPages();
    this.generatePageNumbers();
  }
  calculateTotalPages() {
    this.totalPages = Math.ceil(this.totalItems / this.itemsPerPage);
  }

  generatePageNumbers() {
    this.pageNumbers = Array.from({ length: this.totalPages }, (_, i) => i + 1);
  }

  onPageChanged(page: number) {
    this.currentPage = page;
    this.pageChanged.emit(this.currentPage);
  }
}

