


<app-sidraheader style="position: fixed;width: 100%;top: 0;
left: 0;
z-index: 999;"></app-sidraheader>
<app-menu-header></app-menu-header>
<div class="container-fluid ">
    <div class="col-12 row mx-0 d-flex justify-content-center align-items-center px-4">
          <div class="d-flex align-items-center justify-content-center d-flex flex-column my-4 "  style="height: 70vh; background-color: white;">
              <div class="d-flex justify-content-center">
                  <img src="../../../assets/icons/404page.svg" alt="" width="100">
              </div>
              <div class="d-flex justify-content-center mt-2">
                  <span class="fz-18 fw-500">
                      You are not authorized!
                  </span>
              </div>
              <div class="d-flex justify-content-center w-50 mt-1">
                  <span class="fz-11 fw-500">
                      It seems like you don't have permission to use this page. Please login and continue with this page                    
                  </span>
              </div>
              <div class="d-flex justify-content-center mt-3">
                   <button class="mt-3 update-btn py-2 px-3 fz-12" [routerLink]="['/']" routerLinkActive="router-link-active" >
                      Go to Login Page
                   </button>
              </div>
          </div>
    </div>
</div>

<app-footer></app-footer>