<div class="container-fluid" style="background-color: white;">
    <div class="col-12 row mx-0 d-flex align-items-center sidra-header-main py-3">
        <div class="col-8 d-flex flex-row align-items-center cursor">
            <div class="logo">
                <img src="../../../assets/icons/sidra_logo.png" alt="" [routerLink]="['/']">
            </div>
        </div>
    </div>
</div>








