import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-careerheader',
  templateUrl: './careerheader.component.html',
  styleUrls: ['./careerheader.component.scss']
})
export class CareerheaderComponent implements OnInit {
  isSellWithUsRegistration: boolean = false;
  constructor(private router: Router, private route: ActivatedRoute) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        const isSellWithUsRegistrationRoute = this.route.root.firstChild?.snapshot.routeConfig?.path === 'sellwithus-registration';
        this.isSellWithUsRegistration = isSellWithUsRegistrationRoute;
      }
    });
   }

  ngOnInit(): void {
  }

}
