<div class="card p-3 border-0" >
    <h6 class="fw-bold min-fit-width col-12 mb-2 fz-15 text-capitalize text-truncate-1" >Deal of this week</h6>
    <div class="d-flex flex-wrap row gx-2" dir="rtl">
        <div class="col-6 mt-2"*ngFor="let item of parent" (click)="clickRoute(item.redirect_code,item.redirect_type,item.name)">
            <div class="d-flex justify-content-center align-items-center image-container" style="background-color: #F8F7F5;">
                <img src="{{item.image}}" >
            </div>
            <!-- <div class="deals-heading py-2 d-flex flex-column mt-1">
                <span class="fz-12 fw-bold">Min 20-50% Off</span>
                <span class="fz-10 ">{{item.name}}</span>
            </div> -->
        </div>
    </div>
</div>